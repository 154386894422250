import type { PostForm } from "~/types/forms/post";
import { Post, type PostEntity, type PostPublishBody } from "~/src/api";
import type { AxiosError } from "axios";
import { getPostForm } from "~/src/forms/post/common";

export const tryPublishPost = async (
  postId: string,
  body: PostPublishBody,
  token: string,
  attempts = 0,
): Promise<PostEntity> => {
  let resp;

  try {
    resp = await Post.publish(postId, body, token);
  } catch (e) {
    if ((e as AxiosError)?.response?.status === 425) {
      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), (attempts + 3) * 1000);
      });

      return tryPublishPost(postId, body, token, attempts + 1);
    }

    throw e;
  }

  return resp;
};

export const publishPost = async (
  form: PostForm,
  getToken = async (): Promise<string | null | undefined> => undefined,
): Promise<{ form: PostForm; post: PostEntity }> => {
  const token = (await getToken()) || undefined;
  if (!token) throw new Error("Not logged in");
  if (!form.meta) throw new Error("Post has not been created");

  const post = await tryPublishPost(
    form.meta.postId,
    {
      version: form.meta.version,
      publishAt: form.publishAt,
      publishDelay: form.publishDelay || undefined,
    },
    token,
  );

  return {
    form: {
      ...getPostForm(post),
      conditionMeta: form.conditionMeta,
    },
    post,
  };
};
