<template>
  <div
    class="shadow border-2 relative border-neutral-300 dark:border-neutral-700 rounded-lg text-center overflow-hidden"
  >
    <FeedImageContainer :media="mappedPostMedia" locked />
    <div
      class="flex flex-col justify-center items-center space-y-2 p-4 bg-black/40 text-white"
      :class="{
        'bg-neutral-100 dark:bg-neutral-700': !mappedPostMedia,
        'absolute inset-0': mappedPostMedia,
        'backdrop-blur-2xl': mappedPostMedia.images.length > 1,
      }"
    >
      <IconEyeOff />
      <div class="font-medium">
        {{ translate("components.feedPostContentBanner.header") }}
      </div>
      <div v-if="!settingsStore?.settings.platform.matureContentEnabled">
        {{
          translate(
            `components.feedPostContentBanner.description.${
              authStore?.user ? "enable" : "login"
            }`,
          )
        }}
      </div>
      <NamiButton
        v-if="authStore?.user"
        pill
        :href="
          !settingsStore?.settings.platform.matureContentEnabled
            ? `/${locale}/settings/account-preferences`
            : undefined
        "
        button-type="secondary"
        @buttonClick="emit('show')"
      >
        <div
          class="whitespace-nowrap font-medium tracking-wider uppercase px-2 sm:text-lg !-my-1"
        >
          {{
            settingsStore?.settings.platform.matureContentEnabled
              ? translate("components.feedPostContentBanner.buttons.show")
              : translate("components.feedPostContentBanner.buttons.settings")
          }}
        </div>
      </NamiButton>
      <NamiButton
        v-else
        class="whitespace-nowrap !font-medium tracking-wider uppercase"
        pill
        button-type="secondary"
        @buttonClick="emit('login')"
      >
        {{ translate("components.feedPostContentBanner.buttons.login") }}
      </NamiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconEyeOff } from "@tabler/icons-vue";
import type { CachePost } from "~/src/feed/postUtils";

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const settingsStore = nuxtApp.$settings();
const { locale, t: translate } = nuxtApp.$i18n.global;

const emit = defineEmits<{
  (e: "login"): void;
  (e: "show"): void;
}>();

const props = defineProps<{
  media?: CachePost["postMedia"];
  embed?: CachePost["postEmbed"];
}>();

const mappedPostMedia = computed(() => {
  if (!props.media)
    return {
      mediaId: "sublocked",
      images: [
        {
          spoiler: false,
          blurhash: "LFFk?$VboM-:~U?FxDIp-.soRjof",
          altText: null,
        },
      ],
    };

  return props.media;
});
</script>
