<template>
  <div>
    <NamiLoading v-if="orgTiersPending" />
    <div class="grid gap-4" v-else-if="tiers?.length">
      <SubscriptionListCard
        v-for="tier in tiers"
        :class="{ '!border-nami-nami-blue': selectedIds?.includes(tier.id) }"
        class="cursor-pointer"
        :tier="tier"
        @click="emit('select', getTierEntity(tier.id)!)"
        :lift="lift"
      />
    </div>
    <div v-else class="text-center font-medium">
      {{ $t("components.subscriptionTierSelector.noTiersAvailable") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Meta, Organization, type TierEntity } from "~/src/api";
import { useAsyncOrgTiers } from "~/composables/async/tiers";

const props = defineProps<{
  orgId: string;
  lift?: boolean;
  selectedIds?: string[];
}>();
const emit = defineEmits<{
  (e: "select", v: TierEntity): void;
}>();

const computedOrgId = computed(() => props.orgId);

const asyncOrgTiers = useAsyncOrgTiers(computedOrgId);
const orgTiers = computed(() => asyncOrgTiers.value.data.value);
const orgTiersPending = computed(() => asyncOrgTiers.value.pending.value);

const tiers = computed(() => orgTiers.value?.data ?? []);

const getTierEntity = (id: string) =>
  orgTiers.value?.data.find((tier) => tier.id === id);
</script>

<style scoped lang="postcss"></style>
