<template>
  <Card
    class="sm:tile !p-4 min-w-0 sm:rounded-lg relative text-sm sm:text-base"
    ref="wrapper"
  >
    <div class="gap-2">
      <div class="grid grid-cols-[3.5rem_1fr] gap-2 mb-4 items-center">
        <Skeleton class="h-12 w-12 !rounded-full" />
        <div>
          <Skeleton class="w-[20%] h-6 mr-4" />
          <Skeleton class="w-[8%] h-3 mt-2" />
        </div>
        <IconDots
          :size="20"
          class="absolute top-4 z-10 flex items-center right-4 m-1"
        />
      </div>
      <div class="col-start-2 mb-4">
        <Skeleton class="h-6 w-full" />
        <Skeleton class="h-6 mt-2 w-full" />
        <Skeleton class="h-6 mt-2 w-[35%]" />
        <Skeleton v-if="embed" class="pb-[60%] mt-4 w-full" />
      </div>
      <div
        v-if="!noOptions"
        class="col-start-2 text-sm font-medium flex justify-evenly md:space-x-6 items-center pt-1 -mb-2 border-t border-neutral-200 dark:border-neutral-800"
      >
        <span class="p-2 border border-transparent">
          <IconHeart :size="20" /> React
        </span>
        <span class="p-2 border border-transparent">
          <IconMessagePlus :size="20" /> Reply
        </span>
        <span class="p-2 border border-transparent">
          <IconRefresh :size="20" /> Repost
        </span>
        <span class="p-2 border border-transparent">
          <IconShare :size="20" /> Share
        </span>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import {
  IconDots,
  IconHeart,
  IconMessagePlus,
  IconRefresh,
  IconShare,
} from "@tabler/icons-vue";

defineProps<{ embed?: boolean; noOptions?: boolean }>();

const emit = defineEmits<{
  (e: "size", v: number): void;
}>();

const wrapper = ref();
const updateSize = () => {
  if (!wrapper.value) return;
  const height = wrapper.value.$el.getBoundingClientRect().height;
  emit("size", height);
};

const postSizeObserver = new ResizeObserver(updateSize);
onMounted(() => {
  if (wrapper.value) postSizeObserver.observe(wrapper.value.$el);
  updateSize();
});

onUnmounted(() => postSizeObserver.disconnect());
</script>
