<template>
  <div class="flex [&>*]:flex-grow bg-black" ref="videoWrap" :id="playerId">
    <div v-show="!videoFailed && showVideo" class="flex [&>*]:flex-grow">
      <video ref="videoPlayer" v-bind="{ muted }" class="bg-black">
        <slot></slot>
      </video>
    </div>
    <div
      v-if="thumbnail && !showVideo"
      class="relative bg-black"
      @click="initialize"
    >
      <AsyncImage :src="thumbnail" class="h-full w-full object-contain" />
      <div
        class="absolute inset-0 flex items-center justify-center cursor-pointer bg-black/10 hover:bg-black/40 transition-colors group"
        role="button"
      >
        <div
          class="h-12 w-12 bg-nami-comi-blue shadow border-black border-4 rounded-full flex items-center justify-center group-hover:bg-nami-nami-blue transition-colors"
        >
          <IconPlayerPlayFilled class="text-white" />
        </div>
      </div>
    </div>
    <div
      v-else-if="videoFailed"
      class="bg-neutral-800 text-white flex items-center justify-center flex-col"
    >
      <AsyncImage
        :src="getAbsoluteAssetLink('nami/stickers/sad.png')"
        class="max-w-[30%]"
      />
      <div class="text-center">Video Failed to Load</div>
      <NamiButton
        button-type="primary"
        text
        pill
        no-waves
        :icon="IconReload"
        small
        @buttonClick="initialize"
        >Retry</NamiButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import Hls from "hls.js";
import "plyr/dist/plyr.css";
import type Plyr from "plyr";
import { nanoid } from "nanoid";
import { IconReload, IconPlayerPlayFilled } from "@tabler/icons-vue";

const props = defineProps<{
  src: string;
  thumbnail?: string;
  muted?: boolean;
}>();

const plyr = ref<Plyr>();
const videoPlayer = ref<HTMLVideoElement>();
const videoWrap = ref<HTMLDivElement>();
const playerId = `nami-player-${nanoid()}`;
const videoFailed = ref(true);
const showVideo = ref(!props.thumbnail);

async function createPlyr(
  availableQualities: number[],
  updateQuality = (quality: number) => {},
) {
  if (!process.client) return;
  if (plyr.value) plyr.value.destroy();

  const Plyr = (await import("plyr")).default;
  plyr.value = new Plyr(videoPlayer.value!, {
    controls: [
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "settings",
      "fullscreen",
    ],
    quality: {
      default: 0, // default to auto
      options: availableQualities,
      forced: true,
      onChange: updateQuality,
    },
    autoplay: !showVideo.value,
    i18n: {
      qualityLabel: {
        0: "Auto",
      },
    },
  });
}

function initialize() {
  videoFailed.value = false;
  showVideo.value = true;
  if (props.src.indexOf("blob:") === 0) {
    videoPlayer.value!.src = props.src;
    createPlyr([]);
  } else if (!Hls.isSupported()) {
    videoPlayer.value!.src = props.src;
  } else {
    // For more Hls.js options, see https://github.com/dailymotion/hls.js
    const hls = new Hls({
      maxBufferLength: 10,
      // maxBufferSize: 30,
    });
    hls.loadSource(props.src);
    hls.attachMedia(videoPlayer.value!);
    hls.on(Hls.Events.ERROR, (event, data) => {
      videoFailed.value = data.fatal;
    });
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      const availableQualities = hls.levels.map((level) => level.height);
      availableQualities.unshift(0); // add extra option for auto

      const updateQuality = (quality: (typeof availableQualities)[number]) => {
        if (quality === 0) {
          // this tells hls to use auto quality
          hls.currentLevel = -1;
        } else {
          // search through qualities and set value accordingly
          hls.levels.forEach((level, levelIndex) => {
            if (level.height === quality) {
              hls.currentLevel = levelIndex;
            }
          });
        }
      };

      createPlyr(availableQualities, updateQuality);
    });

    // TODO: this handles appending the auto selected quality to the menu, potentially rework
    hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      const span = document.querySelector(
        `#${playerId} .plyr__menu__container [data-plyr='quality'][value='0'] span`,
      );
      const autoText = hls.autoLevelEnabled
        ? `Auto (${hls.levels[data.level].height}p)`
        : "Auto";

      if (span) {
        span.innerHTML = autoText;
      }

      if (!hls.autoLevelEnabled) return;
      const settingsMenu = document.querySelectorAll(
        `#${playerId} .plyr__controls__item.plyr__menu .plyr__menu__container [data-plyr="settings"]`,
      );
      const qualityMenu = Array.from(settingsMenu).find((elem) =>
        Array.from(elem.children).some((child) =>
          child.innerHTML.includes("Quality"),
        ),
      );
      if (qualityMenu?.children[0].children[0]) {
        qualityMenu.children[0].children[0].innerHTML = autoText;
      }
    });

    // for captions, whenever added
    // player.on('languagechange', () => {
    //   // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
    //   setTimeout(() => hls.subtitleTrack = player.currentTrack, 50);
    // });
  }
}

onMounted(() => {
  if (showVideo.value) initialize();
});
</script>

<style scoped lang="postcss"></style>
