import { type ogDataResponse } from "~/src/namidb";
import axios from "axios";
import { useSessionStorage } from "@vueuse/core";
import { useBatchFetcher } from "~/composables/async/useBatchFetcher";

const debugStyle =
  "color:white;background-color:rgb(5,170,240);padding:2px 4px;border-radius:4px;";
const debug = (...args: Parameters<typeof console.log>) =>
  console.debug("%c%s", debugStyle, ...args);

export interface OGData {
  // this is the url, named id due to batcher requirements
  id: string;
  og: {
    title: string;
    type: string;
    image: string;
    url: string;
    description: string;
  };
  meta: {
    domain: string;
    youtube_id?: string;
    playlist_id?: string;
  };
  cache: {
    expiry: number;
    state: boolean;
    key: string;
  };
}

export const ytMatcher =
  /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/(?:watch\?v=|shorts\/)|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/i;
export const spotifyMatcher =
  /(https:\/\/open.spotify.com\/(episode|track|playlist)\/)([a-zA-Z0-9]+)(.*)/i;
export const bunnycdnMatcher =
  /https:\/\/[\w-]+?.b-cdn.net\/(?:play\/(\d+?)\/)?([\w-]+?)\/?$/;

const CACHE_KEY = "cache.ogData";

const OGDataCache = useSessionStorage<{ [key in string]: OGData }>(
  CACHE_KEY,
  () => ({}),
);
const cacheOGData = (data: OGData) => {
  OGDataCache.value[data.id] = {
    ...data,
  };
};
const getCachedOGData = (id: string) => {
  const data = OGDataCache.value[id];
  if (!data || Date.now() > data.cache.expiry * 1000) {
    delete OGDataCache.value[id];
    return null;
  }

  return data;
};

export const getSupportedUrlMatchers = () => {
  const baseUrl = useBaseUrl();
  const entityMatcher = new RegExp(
    `^${baseUrl}\/\\w+\/(?<type>title|chapter)\/(?<id>\\w+)`,
  );
  return [ytMatcher, spotifyMatcher, bunnycdnMatcher, entityMatcher];
};

export const getOgDataForUrl = async (url: string) =>
  axios
    .get<ogDataResponse>(`https://og.namicomi.com/?url=${url}`)
    .then((res) => res.data);

export const useOgBatch = () => {
  let silence = true;
  if (process.client) {
    const config = useRuntimeConfig();
    silence = config.public.environment === "production";
  }

  return useBatchFetcher(
    "og-batcher",
    async (ids) => {
      // this does not perform refetching of the entire group if a single one is not cached
      // this is due to the lack of batch endpoint from BE
      const cached = ids.map((id) => getCachedOGData(id));
      const toBeFetched = ids.filter((_, idx) => !cached[idx]);

      if (toBeFetched.length) {
        if (!silence) debug("Fetching og data for urls:", ids);
        await Promise.all(
          toBeFetched.map(async (url) => {
            const res = await getOgDataForUrl(url).catch((e) => {});

            if (!res) return;

            cacheOGData({ id: url, ...res });
            return { id: url, ...res };
          }),
        );
      }

      return ids.map((id, idx) => (getCachedOGData(id) || cached[idx])!);
    },
    true,
  );
};

export const useOgData = (url: string) => {
  const batcher = useOgBatch();

  const fetch = async () =>
    getCachedOGData(url) || (await batcher.value.getByIds([url]))[0];

  return {
    ogData: computed(() => getCachedOGData(url)),
    fetch,
  };
};
