import { BatchFetcher } from "~/src/batchable";

export const useBatchFetcher = <
  RetType extends {
    id: string;
  },
>(
  key: string,
  ...args: ConstructorParameters<typeof BatchFetcher<RetType>>
) => {
  if (process.server) {
    return ref({
      getByIds: args[0],
    });
  }

  return useState(key, () => new BatchFetcher<RetType>(...args));
};
