<template>
  <div
    class="shadow border-2 relative border-neutral-300 dark:border-neutral-700 rounded-lg text-center overflow-hidden"
  >
    <FeedImageContainer :media="mappedPostMedia" locked />
    <div
      v-if="(media?.images.length ?? 0) > 1"
      class="absolute bottom-2 right-2 scale-75 translate-x-[12.5%] md:translate-x-0 md:scale-100 md:bottom-4 md:right-4 bg-neutral-600/70 text-xs text-white px-2 py-1 rounded-lg z-[1] backdrop-blur"
    >
      {{
        translate(
          "components.feedPostSubBanner.count.images",
          media!.images.length,
        )
      }}
    </div>
    <div
      class="flex flex-col justify-center items-center space-y-2 p-4 bg-black/40 text-white"
      :class="{
        'bg-neutral-100 dark:bg-neutral-700': !mappedPostMedia,
        'absolute inset-0': mappedPostMedia,
        'backdrop-blur-2xl': mappedPostMedia.images.length > 1,
      }"
    >
      <template v-if="!subbedToOrg.length || userSubPending || pending">
        <IconLock :size="36" />
        <div class="font-medium">
          {{ title || "This post is locked." }}
        </div>
        <div v-if="excerpt">
          {{ excerpt }}
        </div>
      </template>
      <template v-else>
        <div class="font-medium">
          This post has been locked to a higher tier.
        </div>
        <div>
          You need to be subscribed to a tier of equivalent value of
          {{ extractFromLocale(locale, requiredTier?.attributes.name ?? {}) }}
          or higher.
        </div>
      </template>
      <NamiButton
        v-if="!authStore?.user"
        pill
        button-type="primary"
        @buttonClick="emit('login')"
        small
        :disabled="disabled || externalLoading"
        :loading="externalLoading"
        ><span class="px-2">Subscribe to unlock</span></NamiButton
      >
      <NamiButton
        v-else-if="subbedToOrg.length && !userSubPending && !pending"
        pill
        button-type="primary"
        @buttonClick="emit('manage')"
        small
        :disabled="disabled || externalLoading"
        :loading="externalLoading"
        ><span class="px-2">Manage</span></NamiButton
      >
      <NamiButton
        v-else
        pill
        button-type="primary"
        @buttonClick="emit('subscribe')"
        small
        :disabled="disabled || externalLoading"
        :loading="externalLoading"
        ><span class="px-2">Subscribe to unlock</span></NamiButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Gating,
  type GatingConditionEntity,
  Organization,
  Subscriptions,
  type TierEntity,
} from "~/src/api";
import { IconLock } from "@tabler/icons-vue";
import type { CachePost } from "~/src/feed/postUtils";

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const { locale, t: translate } = nuxtApp.$i18n.global;

const props = defineProps<{
  postId?: string;
  orgId?: string;
  title?: string | null;
  excerpt?: string | null;
  externalLoading?: boolean;
  disabled?: boolean;
  media?: CachePost["postMedia"];
}>();

const mappedPostMedia = computed(() => {
  if (!props.media)
    return {
      mediaId: "sublocked",
      images: [
        {
          spoiler: false,
          blurhash: "LFFk?$VboM-:~U?FxDIp-.soRjof",
          altText: null,
        },
      ],
    };

  return props.media;
});

const emit = defineEmits<{
  (e: "login"): void;
  (e: "manage"): void;
  (e: "subscribe"): void;
}>();

const { data: requiredTier, pending } = useAsyncData(
  async () => {
    if (!props.postId || !props.orgId) return;

    const { data: subscriptions } = await Organization.getSubscriptionTiers(
      props.orgId,
      {
        showDeleted: false,
        order: { amount: "asc" },
        currency: "USD",
      },
    );

    const { data: conditions } = await Gating.getConditions(
      "post",
      props.postId,
    );
    const subCondition = conditions.find(
      (cond) => cond.attributes.strategy === "subscribed_tier_amount",
    ) as GatingConditionEntity<"subscribed_tier_amount"> | undefined;

    // discard if invalid, FE is only putting out get requirements
    if (
      !subCondition ||
      subCondition.attributes.parameters.comparison !== "gte"
    )
      return;
    const minAmount = subCondition.attributes.parameters.amount;
    return subscriptions.find(
      (tier) => tier.attributes.minAmounts.USD! >= minAmount,
    );
  },
  {
    immediate: !!props.orgId && !!props.postId,
  },
);

const { data: subbedToOrg, pending: userSubPending } = useAsyncData(
  `user-subbed-org-${props.orgId || "none"}`,
  async () => {
    if (!props.orgId) return [];

    const cached = useNuxtData(`user-subbed-org-${props.orgId || "none"}`).data
      .value;
    if (cached?.length) return cached as TierEntity[];

    const { data: subscriptions } = await Subscriptions.getSubscriptions(
      await getTokenOrThrow(),
      { organizationId: props.orgId, states: ["active"] },
    );

    return subscriptions;
  },
  {
    default() {
      return (
        useNuxtData(`user-subbed-org-${props.orgId || "none"}`).data.value ?? []
      );
    },
    immediate: !!props.orgId,
    server: false,
  },
);
</script>
