<template>
  <div class="relative pb-[60%]" v-bind="$attrs">
    <div
      class="overflow-hidden rounded-md absolute top-0 h-full left-0 w-full grid grid-cols-6 grid-rows-[60%_40%]"
      :class="{
        '!grid-rows-2': pairedImgArray.length === 4,
        'gap-0.5': !locked,
      }"
    >
      <div
        v-for="(image, i) in pairedImgArray.slice(0, maxPreviewImages)"
        class="relative min-w-0 min-h-0 overflow-hidden after:transition-all h-full cursor-pointer after:inset-0 after:absolute hover:after:bg-neutral-500/20"
        :class="{
          'col-span-6':
            pairedImgArray.length === 1 ||
            (pairedImgArray.length === 3 && i === 0),
          'row-span-2': pairedImgArray.length < 3,
          'col-span-3': pairedImgArray.length > 1,
          '!col-span-2': i > 1 && pairedImgArray.length > 4,
        }"
        @click="tryLightbox(i)"
      >
        <Spoiler
          v-if="image.preview && !locked"
          :model-value="!!props.media.images?.[i].spoiler && !!image.preview"
          class="absolute h-full w-full"
        >
          <canvas
            v-if="props.media.images?.[i].spoiler"
            class="h-full w-full object-center object-cover"
            :ref="(el) => createBlurhash(image.hash, el)"
            width="64"
            height="64"
          />
          <AsyncImage
            v-else
            class="w-full h-full object-center object-cover"
            :src="image.preview"
            :alt="image.alt"
          />
        </Spoiler>
        <canvas
          v-else
          class="absolute h-full w-full object-center object-cover"
          :ref="(el) => createBlurhash(image.hash, el)"
          width="64"
          height="64"
        />
        <div
          class="absolute inset-0 text-2xl bg-neutral-800/70 flex items-center justify-center text-center text-white"
          v-if="
            pairedImgArray.length > maxPreviewImages &&
            i === maxPreviewImages - 1 &&
            !locked
          "
        >
          + {{ pairedImgArray.length - maxPreviewImages + 1 }} More
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox
    :visible="visibleRef"
    :imgs="pairedImgArray.map((img) => img.full)"
    :index="indexRef"
    @hide="visibleRef = false"
    teleport="body"
    :zoomScale="1"
  />
</template>

<script setup lang="ts">
import VueEasyLightbox from "vue-easy-lightbox";
import { decode } from "blurhash";
import type { CachePost } from "~/src/feed/postUtils";

interface Props {
  media: NonNullable<CachePost["postMedia"]>;
  locked?: boolean;
}

const maxPreviewImages = 5;
const props = defineProps<Props>();
const { getImageUrl } = useMediaLink();

const getMediaImage = (fileName: string, res?: "256" | "720") =>
  getImageUrl(props.media.mediaId, "post", "post_image", fileName, res)!;
const createBlurhash = (
  hash?: string,
  canvas?: Element | ComponentPublicInstance | null,
) => {
  if (!(canvas instanceof HTMLCanvasElement) || !hash) return;
  const ctx = canvas?.getContext("2d");
  if (!ctx) return;

  const pixels = decode(hash, 64, 64);
  const imageData = ctx.createImageData(64, 64);
  imageData.data.set(pixels);
  ctx.putImageData(imageData, 0, 0);
};

const pairedImgArray = computed(() =>
  props.media.images.map((image) => ({
    preview: image.fileName ? getMediaImage(image.fileName, "720") : "",
    full: image.fileName ? getMediaImage(image.fileName) : "",
    alt: image.altText || undefined,
    hash: image.blurhash,
  })),
);

const unspoil = (index: number) => {
  if (props.media.images[index]) props.media.images[index].spoiler = false;
};

const tryLightbox = (index: number) => {
  if (props.media.images[index] && props.media.images[index].spoiler) {
    unspoil(index);
    return;
  }

  lightbox(index);
};

const visibleRef = ref(false);
const indexRef = ref(0);

function lightbox(index: number) {
  indexRef.value = index;
  visibleRef.value = true;
}
</script>

<style scoped lang="postcss"></style>
