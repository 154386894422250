import type { PostForm } from "~/types/forms/post";
import {
  type GatingConditionEntity,
  isOrg,
  isPostEmbed,
  isPostMedia,
  isRepost,
  type LocalFile,
  type PostEntity,
  type PostTypes,
} from "~/src/api";

export const getDefaultPostForm = (postId?: string): PostForm => {
  return {
    // min 2, max 3000
    content: "",
    // id of the reposted post, if any
    repostId: postId || null,
    // posting as org
    organizationId: null,
    sensitive: false,

    // 1-300 chars
    title: "",
    // 1-1000 chars
    contentExcerpt: "",

    // gating will get determined at create (minAmount = 0 means free)
    minAmount: 0,
    // non-array means video, array means images
    attachments: null,

    publishDelay: null,
    publishAt: null,
  };
};

export const getPostForm = (
  post: PostEntity,
  condition?: GatingConditionEntity<"subscribed_tier_amount">,
  publishAt: string | null = null,
): PostForm => {
  const repostPost = post.relationships.find(isRepost);
  const org = post.relationships.find(isOrg);
  // TODO: support
  const images = post.relationships.filter(isPostMedia);
  const video = post.relationships.filter(isPostEmbed);

  return {
    meta: {
      postId: post.id,
      version: post.attributes.version,
    },
    conditionMeta: condition
      ? {
          id: condition.id,
          version: condition.attributes.version,
        }
      : undefined,

    content: post.attributes.content ?? "",

    minAmount: condition?.attributes.parameters.amount ?? 0,
    organizationId: org?.id || null,
    repostId: repostPost?.id || null,
    sensitive: false,

    attachments: null,

    title: "",
    contentExcerpt: "",

    publishAt: publishAt || post.attributes.publishAt,
    publishDelay: null,
  };
};

export const determinePostKind = (form: PostForm): PostTypes => {
  if (((form.attachments as [] | null)?.length ?? 0) > 0) {
    return "media";
  }

  if ((form.attachments as LocalFile | null)?.data?.type.includes("video")) {
    return "embed";
  }

  if (!form.content && form.repostId && form.repostId !== "") {
    return "repost";
  }

  return "text";
};

export const getPostVideo = (form: PostForm): LocalFile | null => {
  if ((form.attachments as LocalFile | null)?.data?.type.includes("video")) {
    return (form.attachments as LocalFile) || null;
  }
  return null;
};

export const getPostImages = (
  form: PostForm,
): (LocalFile & { spoiler: boolean })[] | null => {
  if ((form.attachments as [] | null)?.length) {
    return form.attachments as (LocalFile & { spoiler: boolean })[];
  }
  return null;
};
