import { nanoid } from "nanoid";
import type { VirtualElement } from "~/composables/feed/virtualizationManager";
import type {
  BadgeRelation,
  ChapterRelation,
  FrameRelation,
  OrganizationEntity,
  OrganizationRelation,
  PopulateRelationship,
  PostEntity,
  PostListParams,
  PostMediaRelation,
  TitleRelation,
  UserEntity,
  UserRelations,
} from "~/src/api";
import type { OGData } from "~/composables/useOGData";
import {
  isBadge,
  isChapter,
  isExpanded,
  isFrame,
  isNews,
  isOrg,
  isPostEmbed,
  isPostMedia,
  isQuote,
  isRelationship,
  isRepost,
  isTitle,
  isUser,
} from "~/src/api";

export type FeedPostType = VirtualElement & {
  post: CachePost | null | undefined;
  commentsOpen: boolean;
  isReplying: boolean;
};

interface ReducedPoster {
  displayName: string;
  id: string;
  avatar: string;
  slug: string; // this doubles as a user's username
  official: boolean;
  verified: boolean;
  type: "user" | "org";
}

export interface BaseCachePost {
  // to tell us if its gated
  subGated: boolean;
  // to tell us if its unlocked
  gatedUnlocked: boolean;
  // for display on locked posts
  excerpt: string | null;

  // linking to this id will always link to the original post even if its reposted
  postId: string;
  author: ReducedPoster;
  authorFrame?: PopulateRelationship<FrameRelation>;
  authorBadges: PopulateRelationship<BadgeRelation>[];
  // org associated with post, ONLY POPULATED WHEN POST IS ORG POST
  org?: ReducedPoster;

  timestamp: string;
  title: string | null;
  message: string;
  postMedia?: CachePostMedia;
  postEmbed?: CachePostEmbed;
  sensitive?: boolean;

  // if is comment portal, this will be populated
  commentTarget?: TitleRelation | ChapterRelation;
  news?: boolean;
}

interface CachePostMedia {
  mediaId: string;
  images: {
    // undefined if gated
    fileName?: string;
    spoiler: boolean;
    blurhash: string;
    altText: string | null;
  }[];
}

interface CachePostEmbed {
  embedId?: string;
  blurhash?: string;
  thumbnail?: string;
  url: string;
  ogData?: Omit<OGData, "id">;
}

export interface CachePost extends BaseCachePost {
  // ONLY POPULATED WHEN POST IS A REPOST OR QRP
  repostData?: {
    // this is the id of the original post entity if it has a repost
    originalPostId: string;
    // timestamp of original post (unused atm)
    originalPostTimestamp: string | null;
    // this is the array of reposters, ONLY POPULATED WHEN SOFT REPOST
    repostedBy: ReducedPoster[];
    // the quoted post, ONLY POPULATED WHEN QRP
    targetPost?: BaseCachePost;
  };
}

export const POST_INCLUDES: PostListParams["includes"] = [
  "organization",
  "user",
  "post_embed",
  "post_media",
  "emote",
  "profile_frame",
  "gated_post_media",
  "gated_post_embed",
  "badge",
];

export function generateSkeletonPosts(amount: number): FeedPostType[] {
  return new Array(amount).fill(null).map(() => ({
    id: nanoid(),
    post: Math.random() > 0.75 ? undefined : null,
    offset: 0,
    size: 0,
    commentsOpen: false,
    isReplying: false,
    loading: true,
  }));
}

export function copyPostUrl(post: CachePost) {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const appStore = nuxtApp.$app();

  const url = new URL(config.public.baseUrl);
  url.pathname = linkTo("post", post.postId);

  copyTextToClipboard(url.href)?.then(() =>
    appStore?.notify({
      preset: "success.plain",
      detail: "Link has been copied to clipboard",
      timer: 3000,
    }),
  );
}

export const hasQuote = (p: PostEntity) => !!p.relationships.find(isQuote);

export const getLastUrl = (text?: string | null) => {
  if (!text) return null;
  const lastUrl = getLastURL(text)?.[1];
  return !lastUrl?.match(
    /namicomi\.(com|dev)\/[a-z]{2,3}(-(?:[0-9a-z]{2,4}|roman))?\/post\/[A-Za-z0-9]{32}/,
  )
    ? lastUrl
    : null;
};

export const formatUser = (
  user?: UserEntity | UserRelations,
): ReducedPoster => {
  const { getUserAvatar } = useMediaLink();

  if (!user || !isExpanded(user)) {
    return {
      id: "",
      slug: "",
      displayName: "",
      avatar: getAbsoluteAssetLink("nami/profile/sea-deity.png"),
      type: "user",
      official: false,
      verified: false,
    };
  }

  return {
    id: user?.id ?? "",
    slug: user?.attributes?.username ?? "",
    displayName:
      user?.attributes?.displayName ?? user?.attributes?.username ?? "",
    avatar: user?.attributes ? getUserAvatar(user, "128") : "",
    type: "user",
    official: !!user?.attributes.isOfficial,
    verified: !!user?.attributes.isVerified,
  };
};

export const formatOrg = (
  org?: OrganizationEntity | PopulateRelationship<OrganizationRelation>,
): ReducedPoster => {
  const { getOrganizationAvatar } = useMediaLink();

  if (!org) {
    return {
      id: "",
      slug: "",
      displayName: "",
      avatar: getAbsoluteAssetLink("nami/profile/sea-deity.png"),
      type: "org",
      official: false,
      verified: false,
    };
  }
  return {
    id: org?.id ?? "",
    slug: org?.attributes?.slug ?? "",
    displayName: org?.attributes?.name ?? "",
    avatar: org?.attributes ? getOrganizationAvatar(org, "128") : "",
    type: "org",
    official: org?.attributes.official,
    verified: org.attributes.verified,
  };
};

export function formatBasePost(
  post: PostEntity,
  baseUrl: string,
  embedPost = true,
): BaseCachePost {
  const postAuthor = post.relationships.find(isUser);
  const postAuthorBadges = post.relationships
    .filter(isBadge)
    .filter(isExpanded);
  const postOrg = post.relationships.find(isOrg);
  const attachedPost =
    post.relationships.find(isRepost) ?? post.relationships.find(isQuote);
  const attachedEmbed = post.relationships.find(isPostEmbed);
  const attachedMedia = post.relationships.find(isPostMedia);
  const subGated = post.type === "gated_post" || !!post.attributes.gating;
  const gatedUnlocked = post.type === "post" && !!post.attributes.gating;
  const foundFrame = post.relationships.find(isFrame);
  const authorFrame =
    foundFrame && isExpanded(foundFrame) ? foundFrame : undefined;

  let attach: CachePostMedia | undefined;
  let embed: CachePostEmbed | undefined;
  let quotePostUrl: string | undefined;
  if (attachedEmbed && isExpanded(attachedEmbed)) {
    if (attachedEmbed.attributes.url) {
      // give embed priority to video urls
      embed = {
        embedId: attachedEmbed.id,
        thumbnail: attachedEmbed.attributes.thumbnailFilename,
        blurhash: attachedEmbed.attributes.blurhash,
        url: attachedEmbed.attributes.url,
      };
    } else if (attachedEmbed.attributes.blurhash) {
      // if it is gated, show blurhash
      attach = {
        mediaId: attachedEmbed.id,
        images: [
          {
            spoiler: false,
            blurhash: attachedEmbed.attributes.blurhash,
            altText: null,
          },
        ],
      };
    }
  }

  // if no video embed, take last url from message
  const lastUrl = getLastUrl(post.attributes.content);
  if (!embed && lastUrl && !(embedPost && quotePostUrl)) {
    embed = { url: lastUrl };
  }

  if (hasQuote(post)) {
    quotePostUrl = attachedPost?.id
      ? baseUrl + linkTo("post", attachedPost.id)
      : undefined;
  }
  if (attachedMedia) {
    const media: CachePostMedia = {
      mediaId: attachedMedia.id,
      images: (attachedMedia as PostMediaRelation).attributes?.files ?? [],
    };
    if (media.images.length > 0) attach = media;
  }

  return {
    subGated,
    gatedUnlocked,
    news: post.relationships.some(isNews),
    postId: post.id,
    author: formatUser(postAuthor),
    authorBadges: postAuthorBadges,
    authorFrame,
    org: postOrg && isExpanded(postOrg) ? formatOrg(postOrg) : undefined,
    timestamp: post.attributes.publishAt || post.attributes.createdAt,
    message:
      (post.attributes.content ?? "") +
      (embedPost && quotePostUrl ? "\n" + quotePostUrl : ""),
    title: post.attributes.title,
    excerpt: post.attributes.contentExcerpt,
    postMedia: attach,
    postEmbed: embed,
    sensitive: post.attributes.sensitive,
    commentTarget: post.relationships.find(
      (rel) => isTitle(rel) || isChapter(rel),
    ) as TitleRelation | ChapterRelation | undefined,
  };
}
