<template>
  <div v-if="!error">
    <div
      v-if="videoSrc"
      class="rounded-md overflow-hidden relative pb-[56.25%]"
    >
      <NamiVideoPlayer
        :src="videoSrc"
        :thumbnail="thumbnailSrc"
        class="absolute inset-0"
        muted
      />
    </div>
    <div v-if="fallbackIframe" :class="fallbackIframe.parentClass">
      <iframe v-bind="fallbackIframe.iFrameAttrs" />
    </div>
    <TheNuxtLink v-else-if="entity && isChapter(entity)" :href="taggedUrl">
      <ChapterCard :chapter="entity" :fallback-url="fallbackUrl" no-link />
    </TheNuxtLink>
    <TheNuxtLink
      v-else-if="entity && isTitle(entity)"
      :href="linkTo(entity) + '?s=s'"
    >
      <TitleCard
        class="bg-neutral-100 dark:bg-neutral-800 canhover:hover:bg-neutral-200 canhover:dark:hover:bg-neutral-700 p-2 transition-colors"
        :title="entity"
        :small="!sm"
      />
    </TheNuxtLink>
    <template v-else-if="embedData">
      <div
        v-if="embedData.og.type === 'image' && !isExternalGif"
        class="pb-[60%] relative"
      >
        <div
          class="absolute top-0 h-full left-0 w-full after:transition-all cursor-pointer after:inset-0 after:absolute hover:after:bg-neutral-500/20 overflow-hidden rounded-md"
          @click="lightbox(embedData.og.image)"
        >
          <AsyncImage
            class="absolute h-full w-full object-center object-cover"
            :src="embedData.og.image"
            alt=""
          />
        </div>
        <VueEasyLightbox
          :visible="visibleRef"
          :imgs="imgsRef"
          :index="indexRef"
          @hide="visibleRef = false"
          teleport="body"
          :zoomScale="1"
        />
      </div>
      <TheNuxtLink
        v-else
        :href="
          embed.url.indexOf(config.public.baseUrl) === 0
            ? embed.url
            : `https://link.namicomi.com/?url=${embed.url}`
        "
        rel="noopener noreferrer nofollow"
        :target="
          embed.url.indexOf(config.public.baseUrl) === 0 ? '_self' : '_blank'
        "
        class="shadow border border-neutral-300 dark:border-neutral-700 rounded-md flex flex-col text-xs sm:text-sm overflow-hidden"
        :class="{
          ' pb-[60%] relative':
            !embedData.og.image.includes(
              'https://og.namicomi.com/og-empty.png',
            ) && !embedData.og.image.endsWith('.gif'),
        }"
        :title="embed.url"
      >
        <div
          :class="{
            'grid grid-rows-[1fr_auto] overflow-hidden rounded-md absolute top-0 h-full left-0 w-full':
              !embedData.og.image.includes(
                'https://og.namicomi.com/og-empty.png',
              ) && !isExternalGif,
          }"
        >
          <div
            v-if="
              !embedData.og.image.includes(
                'https://og.namicomi.com/og-empty.png',
              ) && !isExternalGif
            "
            class="relative overflow-hidden"
          >
            <AsyncImage
              class="absolute top-0 h-full left-0 w-full object-cover flex-shrink"
              :src="embedData.og.image"
            />
          </div>
          <div class="p-4 py-2 xl:py-4">
            <p class="line-clamp-1 opacity-70">
              {{ embedData.meta.domain }}
            </p>
            <p class="line-clamp-1 font-medium">{{ embedData.og.title }}</p>
            <p class="line-clamp-2 opacity-70">
              {{ embedData.og.description }}
            </p>
          </div>
        </div>
      </TheNuxtLink>
    </template>
  </div>
</template>

<script lang="ts" setup>
import VueEasyLightbox from "vue-easy-lightbox";
import {
  bunnycdnMatcher,
  getOgDataForUrl,
  spotifyMatcher,
  ytMatcher,
} from "~/composables/useOGData";
import { Chapter, isChapter, isTitle, Title } from "~/src/api";
import type { CachePost } from "~/src/feed/postUtils";

const props = defineProps<{
  postId: string;
  embed: NonNullable<CachePost["postEmbed"]>;
}>();

const { sm } = useBreakpoints();
const fallbackIframe = ref();
const config = useRuntimeConfig();
const { getImageUrl } = useMediaLink();

const entityMatcher = new RegExp(
  `^${config.public.baseUrl}\/\\w+\/(?<type>title|chapter)\/(?<id>\\w+)`,
);

const taggedUrl = computed(() => {
  const constructor = new URL(props.embed.url, config.public.baseUrl);
  // s = social source
  constructor.searchParams.set("s", "s");
  return constructor.pathname + constructor.search;
});

const FEparsableArr = [
  {
    matcher: ytMatcher,
    getIframeAttrs: (url: string) => {
      const parsed = ytMatcher.exec(url);
      if (!parsed) return {};

      return {
        src: `https://www.youtube.com/embed/${parsed[1]}`,
        allow: "autoplay; clipboard-write; encrypted-media; fullscreen;",
        class: "absolute top-0 h-full left-0 w-full",
      };
    },
    parentClass: "pb-[56.25%] relative rounded-md overflow-hidden",
  },
  {
    matcher: spotifyMatcher,
    getIframeAttrs: (url: string) => {
      const parsed = spotifyMatcher.exec(url);
      if (!parsed) return {};

      return {
        src: `https://open.spotify.com/embed/${parsed[2]}/${parsed[3]}`,
        allow:
          "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
        loading: "lazy",
        width: "100%",
        frameBorder: "0",
        style: "border-radius:12px",
        height: parsed[2] === "track" ? "80" : "160",
      };
    },
    parentClass: "",
  },
  {
    matcher: bunnycdnMatcher,
    getIframeAttrs: (url: string) => {
      const parsed = bunnycdnMatcher.exec(url);
      if (!parsed) return {};

      return {
        src: `https://iframe.mediadelivery.net/embed/${parsed[1]}/${parsed[2]}`,
        allow:
          "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;",
        allowfullscreen: "true",
        class: "absolute top-0 h-full left-0 w-full",
        loading: "lazy",
      };
    },
    parentClass: "pb-[56.25%] relative rounded-md overflow-hidden",
  },
];

// for video
const videoSrc = computed(() => {
  const matched = bunnycdnMatcher.exec(props.embed.url);
  if (!matched) return;
  return props.embed.url.replace(`/play/${matched[1]}`, "") + "/playlist.m3u8";
});

const thumbnailSrc = computed(() => {
  if (props.embed.thumbnail) {
    return getImageUrl(
      props.postId,
      "post",
      "post_image",
      props.embed.thumbnail,
      "720",
    );
  }
});

const match = props.embed.url.match(entityMatcher);
const { id, type } = match?.groups ?? {};
const { getCoverUrl } = useMediaLink();

const fallbackUrl = ref("");

const {
  data: entity,
  pending: entityPending,
  error,
} = useAsyncData(`${type ?? "none"}-${id ?? "none"}`, async () => {
  if (match) {
    switch (type) {
      case "chapter":
        const res = await Chapter.get(id, []);
        const title = res.relationships.find(isTitle);
        if (title) {
          const titleEntity = await Title.get(title.id, ["cover_art"]);
          fallbackUrl.value = getCoverUrl(titleEntity);
        }

        return res;
      case "title":
        return await Title.get(id, ["cover_art", "organization"]);
      default:
        return null;
    }
  }

  return null;
});

const { data: embedData } = useAsyncData(
  `og-data-${props.embed.url}`,
  async () => {
    const url = props.embed.url;

    if (bunnycdnMatcher.test(url)) {
      return null;
    }
    if (entityMatcher.test(url)) {
      return null;
    }
    for (const parsable of FEparsableArr) {
      if (parsable.matcher.test(url)) {
        fallbackIframe.value = {
          parentClass: parsable.parentClass,
          iFrameAttrs: parsable.getIframeAttrs(url),
        };
        return null;
      }
    }
    if (props.embed) {
      // lets leave issues of caching to the backend for now
      return await getOgDataForUrl(props.embed.url);
    }

    return null;
  },
  { watch: [() => props.embed.url] },
);

// animations arent enforcable
const isExternalGif = computed(
  () => (!embedData.value ? false : embedData.value.og.image.endsWith(".gif")),
  // && !ogData.value.og.image.includes('uploads.namicomi')
);

//lightbox code

type ImgRef =
  | string
  | string[]
  | { title: string; src: string }
  | { title: string; src: string }[];

const visibleRef = ref(false);
const indexRef = ref(0);
const imgsRef = ref<ImgRef>([]);

function lightbox(url: string) {
  imgsRef.value = url;
  visibleRef.value = true;
}
</script>
