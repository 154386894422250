<template>
  <div
    class="composer-border relative p-4 post-wrap"
    :class="{ locked: postForm.minAmount }"
    v-bind="$attrs"
  >
    <div
      class="grid grid-cols-[1fr_auto] gap-x-4 mb-4"
      :class="{ disabled: submitting }"
    >
      <PostAuthorSelect
        class="mr-auto -my-2 -ml-2"
        v-model="postForm.organizationId"
        @update:modelValue="() => (postForm.minAmount = 0)"
        :user="user"
        perm-check="post"
        :sync="sync"
      >
        <template #subtext v-if="postForm.minAmount || postForm.publishAt">
          <div
            class="min-w-0 inline-flex items-center truncate text-xs sm:text-sm text-neutral-500 break-all"
          >
            <span
              v-if="postForm.minAmount"
              class="text-yellow-600 dark:text-yellow-400"
              >${{ postForm.minAmount / 100 }}+/month</span
            >
            <template v-if="postForm.publishAt">
              <IconCalendarClock
                class="inline-block mr-1"
                :class="{ 'ml-2': postForm.minAmount }"
                :size="16"
              />
              <span>{{ dayjs(postForm.publishAt).format("L LT") }}</span>
            </template>
          </div>
        </template>
      </PostAuthorSelect>
      <div class="flex items-start space-x-2">
        <ContextMenu closeOnOutsideClick>
          <template #facing="{ isOpen, open, close }">
            <NamiButton
              button-type="secondary"
              :icon="IconDots"
              small
              pill
              text
              @buttonClick="() => (isOpen ? close() : open())"
              :disabled="submitting"
            />
          </template>
          <template #menu>
            <div>
              <NamiButton
                class="!justify-start"
                :button-type="postForm.sensitive ? 'danger' : 'secondary'"
                :icon="postForm.sensitive ? IconEyeOff : IconEye"
                small
                text
                @buttonClick="() => (postForm.sensitive = !postForm.sensitive)"
                block
                >{{
                  postForm.sensitive
                    ? "Mark post as safe"
                    : "Mark post as sensitive"
                }}
              </NamiButton>
            </div>
          </template>
        </ContextMenu>
        <NamiButton
          v-if="showClose"
          class="mb-auto"
          button-type="secondary"
          small
          pill
          text
          :icon="IconX"
          @buttonClick="emit('close')"
          :disabled="submitting"
        />
      </div>
    </div>
    <div class="min-w-0 relative" :class="{ disabled: submitting }">
      <div class="relative w-full">
        <TransitionAutoHeight :model-value="!!postForm.minAmount">
          <input
            placeholder="Add a Title (Optional)"
            class="border-none p-0 outline-none block !ring-0 font-medium w-full bg-transparent placeholder:text-inherit placeholder:opacity-70"
            v-model="postForm.title"
          />
          <textarea
            class="border-none p-0 outline-none block !ring-0 w-full bg-transparent mt-2 placeholder:text-inherit placeholder:opacity-70"
            :placeholder="
              getExcerpt(postForm.content)
                ? getExcerpt(postForm.content).trim() + '...'
                : 'Preview text non-subscribers will see. (Optional, autogenerated if empty)'
            "
            v-model="postForm.contentExcerpt"
          ></textarea>
          <NamiDivider />
        </TransitionAutoHeight>
        <ClientOnly>
          <NamiRichTextEditor
            class="text-sm sm:text-base"
            :class="textboxClass ?? 'min-h-12'"
            v-model="postForm.content"
            @paste="(e) => updateFilesFromPaste(processImagePaste(e))"
            :disabled="submitting"
            :disabled-emote-tabs="['stickers']"
            :max-emotes="5"
            placeholder="Start a post"
            ref="richTextEditor"
          >
            <template #toolbarBefore>
              <NamiButton
                button-type="primary"
                :icon="IconPhotoPlus"
                small
                pill
                text
                @buttonClick="doAddFile"
                :disabled="submitting || !!attachedVideo"
              />
              <Tooltip
                fixed
                :disabled="!!postForm.organizationId && orgHasTiers"
              >
                <NamiButton
                  button-type="primary"
                  :icon="postForm.minAmount ? IconLock : IconLockOpen"
                  small
                  text
                  @buttonClick="
                    ((showSubSelectModal = true), (showMessageForm = false))
                  "
                  block
                  pill
                  no-waves
                  :disabled="!postForm.organizationId || !orgHasTiers"
                />
                <template #tooltip>
                  {{
                    postForm.organizationId && !orgHasTiers
                      ? "Create a tier to lock this post"
                      : "You can only lock posts made by organizations"
                  }}
                </template>
              </Tooltip>
              <PostComposerScheduleButton
                v-model:publish-at="postForm.publishAt"
                :user="user"
                @post="(v) => emit('post', v)"
                ref="postScheduler"
              />
            </template>
            <template #toolbarAfter>
              <div
                class="!ml-auto text-xs transition-opacity opacity-0 self-center pointer-events-none"
                :class="{
                  'opacity-100': postForm.content.length > 2750,
                  'text-red-400': postForm.content.length > 3000,
                }"
              >
                {{ postForm.content.length }}/3000
              </div>
              <NamiButton
                :button-type="
                  postForm.minAmount
                    ? 'gold'
                    : postForm.sensitive
                      ? 'danger'
                      : 'primary'
                "
                small
                pill
                no-waves
                @buttonClick="submit"
                :disabled="
                  submitting ||
                  postForm.content.length > 3000 ||
                  (!postForm.content.trim() && !postForm.attachments)
                "
              >
                <span class="px-1 space-x-1 inline-flex items-center">
                  <span>Post</span>
                  <IconSend :size="18" />
                </span>
              </NamiButton>
            </template>
            <template #between>
              <TransitionAutoHeight
                :model-value="!!attachedVideo || attachedImages.length > 0"
              >
                <div
                  v-if="attachedImages.length > 0"
                  class="pt-4 overflow-x-auto min-w-0 overflow-y-visible flex flex-col"
                >
                  <div class="flex gap-4 min-w-0 w-0">
                    <div
                      v-for="(file, i) in attachedImages"
                      class="relative min-h-[8rem] min-w-[8rem] max-h-[8rem] max-w-[8rem]"
                    >
                      <NamiFile
                        class="absolute top-0 h-full w-full left-0"
                        :class="{ disabled: submitting }"
                        :blob-url="file.blobUrl"
                        :filename="file.filename"
                        :blur="file.spoiler"
                        @remove="removeFile(file.id!)"
                        @re-crop="lightbox(i)"
                      />
                      <div class="absolute -right-2 top-6">
                        <NamiButton
                          :buttonType="file.spoiler ? 'danger' : 'secondary'"
                          :icon="file.spoiler ? IconEyeOff : IconEye"
                          pill
                          small
                          @click="toggleIndexSpoiler(i)"
                          block
                        />
                      </div>
                    </div>
                  </div>
                  <NamiButton
                    class="mt-1 !sticky left-0 mr-auto"
                    button-type="danger"
                    small
                    pill
                    text
                    @buttonClick="clearAttachments"
                    :disabled="submitting"
                  >
                    <span class="px-1 flex items-center">
                      <span class="mr-1">Clear All</span> <IconX :size="18" />
                    </span>
                  </NamiButton>
                </div>
                <div
                  v-if="getPostVideo(postForm)"
                  class="pt-2 overflow-x-auto min-w-0 overflow-y-visible flex flex-col"
                >
                  <div class="relative flex flex-col">
                    <div
                      class="rounded-md overflow-hidden relative pb-[56.25%] shadow"
                    >
                      <NamiVideoPlayer
                        :src="getPostVideo(postForm)!.blobUrl"
                        class="absolute inset-0"
                        muted
                      />
                    </div>
                    <NamiButton
                      class="mt-1 ml-auto"
                      button-type="danger"
                      small
                      pill
                      text
                      @buttonClick="clearAttachments"
                      :disabled="submitting"
                      icon-position="right"
                      :icon="IconX"
                    >
                      Remove
                    </NamiButton>
                  </div>
                </div>
              </TransitionAutoHeight>
            </template>
          </NamiRichTextEditor>
        </ClientOnly>
        <input
          type="file"
          :accept="AcceptMime.concat(AcceptMimeVideo).join(',')"
          multiple
          hidden
          ref="fileInput"
          @change="updateFilesFromInput"
        />
      </div>
      <div
        v-if="quotePostId"
        class="overflow-x-auto min-w-0 overflow-y-visible"
      >
        <FeedPostSkeleton v-if="attachedPostPending" no-options />
        <FeedPostVirtual
          v-else-if="attachedPost"
          class="mt-4 border border-neutral-300 dark:border-neutral-700"
          :feed-item="attachedPost"
          :comments-open="false"
          :is-replying="false"
          embed
        />
      </div>
    </div>
    <div
      v-if="submitting"
      class="absolute inset-0 flex flex-col justify-center items-center"
    >
      <NamiLoading class="!h-auto" size="small" />
      <div v-if="postForm.attachments" class="post-progress">
        <div class="relative text-center" style="z-index: 1">
          {{ uploadProgress.toFixed(0) }}%
        </div>
        <div
          class="dark:none absolute top-0 left-0 w-full text-center text-white"
          style="z-index: 1"
          :style="{ clipPath: `inset(0 ${100 - uploadProgress}% 0 0 )` }"
        >
          {{ uploadProgress.toFixed(0) }}%
        </div>
      </div>
      <div
        v-else
        class="mt-2 bg-nami-comi-blue px-4 py-0.5 rounded-full text-white"
      >
        {{ processing ? "Publishing..." : "Creating..." }}
      </div>
    </div>
  </div>
  <NamiModal v-model="showSubSelectModal" large prevent-outside-clicks>
    <NamiModalTitle @close="showSubSelectModal = false"
      >Select a Tier
    </NamiModalTitle>
    <div class="space-y-4">
      <div>
        Any user that subscribes with an equivalent or higher value to the
        selected tier will have access.
      </div>
      <div class="sm:h-[50vh] flex flex-col gap-4">
        <SubscriptionTierSelector
          v-show="!showMessageForm || !postForm.minAmount"
          class="overflow-auto w-full"
          style="scrollbar-gutter: stable"
          v-if="postForm.organizationId && showSubSelectModal"
          :selected-ids="postForm.minAmount > 0 ? [minTier!.id] : []"
          :org-id="postForm.organizationId"
          @select="selectMinTier"
        />
      </div>
      <div class="flex gap-4 flex-col sm:flex-row">
        <NamiButton
          button-type="secondary"
          block
          :icon="IconLockOpen"
          @button-click="
            () => (
              (showSubSelectModal = false),
              (showMessageForm = false),
              (postForm.minAmount = 0),
              (minTier = null)
            )
          "
          >Unlock Post
        </NamiButton>
        <NamiButton
          button-type="primary"
          @buttonClick="showSubSelectModal = false"
          block
          class="mt-auto"
          >Save
        </NamiButton>
      </div>
    </div>
  </NamiModal>
  <VueEasyLightbox
    teleport="body"
    :visible="visibleRef"
    :imgs="attachedImages.map((x) => x.blobUrl)"
    :index="indexRef"
    @hide="visibleRef = false"
    rotate-disabled
  />
</template>

<script setup lang="ts">
import {
  IconCalendarClock,
  IconDots,
  IconEye,
  IconEyeOff,
  IconLock,
  IconLockOpen,
  IconPhotoPlus,
  IconSend,
  IconX,
} from "@tabler/icons-vue";
import {
  Organization,
  Post,
  type PostEntity,
  type TierEntity,
  type UserEntity,
} from "~/src/api";
import VueEasyLightbox from "vue-easy-lightbox";
import { nanoid } from "nanoid";
import "vue3-emoji-picker/css";
import { type Fn, useEventListener } from "@vueuse/core";
import FeedPostVirtual from "~/components/feed/post/FeedPostVirtual.vue";
import { processImagePaste } from "~/utils/ui/pasteHandler";
import { formatBasePost } from "~/src/feed/postUtils";
import {
  getDefaultPostForm,
  getPostImages,
  getPostVideo,
} from "~/src/forms/post/common";
import { createPost } from "~/src/forms/post/create";
import type { PostImageFile } from "~/types/forms/post";
import { publishPost } from "~/src/forms/post/publish";
import { getExcerpt } from "~/utils/ui/excerpt";
import type NamiRichTextEditor from "../nami/NamiRichTextEditor.vue";
import { usePostDraft } from "~/composables/usePostDraft";
import type { PostComposerScheduleButton } from "#components";

interface Props {
  user?: UserEntity;
  quotePostId?: string;
  showClose?: boolean;
  textboxClass?: string;
  noPostOptions?: boolean;
  sync?: boolean;
}

const dayjs = useDayjs();
const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();
const translate = nuxtApp.$i18n.global.t;
const config = useRuntimeConfig();

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "post", v: PostEntity): void;
  (e: "close"): void;
}>();

const postForm = ref(getDefaultPostForm(props.quotePostId));
const attachedVideo = computed(() => getPostVideo(postForm.value));
const attachedImages = computed(() => getPostImages(postForm.value) ?? []);
const uploadProgress = ref(0);
const richTextEditor = ref<InstanceType<typeof NamiRichTextEditor>>();
const uploadProgressStyle = computed(() => uploadProgress.value + "%");

const { storedMessage, clearMessage } = usePostDraft();

if (props.sync)
  watch(storedMessage, (v) => (postForm.value.content = v), {
    immediate: true,
  });

onMounted(() => {});

const { data: attachedPost, pending: attachedPostPending } = useLazyAsyncData(
  async () => {
    if (!props.quotePostId) return;
    const post = await Post.getPost(
      props.quotePostId,
      ["post_embed", "post_media", "user", "organization", "emote"],
      (await authStore?.getToken()) || undefined,
    );

    return formatBasePost(post, config.public.baseUrl);
  },
);

const visibleRef = ref(false);
const indexRef = ref(0);
const fileInput = ref<HTMLInputElement>();
const textInput = ref<HTMLTextAreaElement>();

const showSubSelectModal = ref(false);
const showMessageForm = ref(false);
const minTier = ref<TierEntity | null>(null);

const showEmojiPickerButton = ref<HTMLDivElement>();
const emojiPickerContainer = ref<HTMLDivElement>();
const showEmojiPicker = ref(false);
const postScheduler = ref<InstanceType<typeof PostComposerScheduleButton>>();

// For removing our @vueuse/core event listenres
let cleanup: Fn | null = null;

watch(showEmojiPicker, (v) => {
  if (v) {
    textInput.value?.focus();

    cleanup = useEventListener(document, "click", (e) => {
      if (!showEmojiPickerButton.value || !emojiPickerContainer.value) {
        return;
      }

      if (
        !isClickInsideElements(e, [
          showEmojiPickerButton.value,
          emojiPickerContainer.value,
        ])
      ) {
        showEmojiPicker.value = false;
      }
    });
  } else {
    cleanup?.call(null);
  }
});

const processing = ref(false);

const { pending: submitting, action: submit } = useAction2(async () => {
  uploadProgress.value = 0;

  let post;
  try {
    const draftForm = await createPost(
      postForm.value,
      authStore?.getToken,
      (p) => (uploadProgress.value = p * 100),
    );
    processing.value = true;
    post = (await publishPost(draftForm, authStore?.getToken)).post;
  } catch (e) {
    const { title, detail } = resolveError(e);
    appStore?.notify({
      preset: "error.plain",
      title,
      detail,
      timer: 5000,
    });
    processing.value = false;
    return;
  } finally {
    processing.value = false;
  }

  clearAttachments();
  const orgId = postForm.value.organizationId;
  clearMessage();
  postForm.value = getDefaultPostForm(props.quotePostId);
  postForm.value.organizationId = orgId;

  if (
    post.attributes.state === "pending_media_upload" ||
    post.attributes.state === "publish_delayed"
  ) {
    postScheduler.value?.refreshPending();
  }

  emit("post", post);
});

const selectMinTier = (tier: TierEntity) => {
  minTier.value = tier;
  postForm.value.minAmount = tier.attributes.minAmounts.USD!;
  showSubSelectModal.value = false;
};

const { data: orgHasTiers } = useAsyncData(
  async () => {
    const ordId = postForm.value.organizationId;
    if (!ordId) return false;

    return (
      (
        await Organization.getSubscriptionTiers(ordId, {
          currency: "USD",
          showDeleted: false,
          limit: 0,
        })
      ).meta.total > 0
    );
  },
  { watch: [() => postForm.value.organizationId], default: () => false },
);

// image handling functions
const AcceptMime = [".jpg", ".jpeg", ".gif", ".png"];

function doAddFile() {
  fileInput.value?.click();
}

function lightbox(index: number) {
  indexRef.value = index;
  visibleRef.value = true;
}

function toggleIndexSpoiler(index: number) {
  attachedImages.value[index].spoiler = !attachedImages.value[index].spoiler;
}

function updateFilesFromInput() {
  const inputElement = fileInput.value;
  if (!inputElement) throw new Error("Could not find input element.");

  const files = Array.from(inputElement.files ?? []);
  if (files.length === 0) return;
  const video = files.filter((file) => file.type.includes("video"));
  const images = files.filter((file) => file.type.includes("image"));

  if (video.length > 0) {
    if (video.length > 1) {
      appStore?.notify({
        preset: "error.plain",
        title: "Error uploading files",
        detail: "Only one video is allowed",
      });
      return;
    }

    if (images.length > 0) {
      appStore?.notify({
        preset: "error.plain",
        title: "Error uploading files",
        detail: "You can only upload one video or up to 10 images",
      });
      return;
    }

    updateFilesFromVideo(video[0]);
    inputElement.value = "";
    return;
  }

  const newFiles = files.filter(
    (file) =>
      !attachedImages.value.some(
        (existingFile) => file.name === existingFile.filename,
      ),
  );

  updateFiles(newFiles);

  inputElement.value = "";
}

async function updateFilesFromPaste(newFiles: File[]) {
  if (!newFiles.length || !appStore) return;
  if (
    (postForm.value.attachments as [])?.length === undefined &&
    postForm.value.attachments
  ) {
    const result = await appStore.prompt(
      translate("components.PostAddEditForm.modals.confirm_overwrite.title"),
      {
        detail: translate(
          "components.PostAddEditForm.modals.confirm_overwrite.detail",
        ),
        icon: "confused",
        forceCloseKey: "cancel",
        buttons: {
          cancel: {
            buttonType: "secondary",
            buttonText: translate(
              "components.PostAddEditForm.modals.confirm_overwrite.buttons.cancel",
            ),
          },
          confirm: {
            buttonType: "danger",
            buttonText: translate(
              "components.PostAddEditForm.modals.confirm_overwrite.buttons.overwrite",
            ),
          },
        },
      },
    );

    if (result === "cancel") return;
  }

  updateFiles(newFiles);
}

function updateFiles(newFiles: File[]) {
  postForm.value.attachments = attachedImages.value.concat(
    newFiles.map((x: File) => {
      const localId = nanoid();
      return {
        source: "local",
        filename: x.name,
        data: x,
        blobUrl: URL.createObjectURL(x),
        index: 0,
        id: localId,
        internalId: localId,
        internalBatchId: "",
        progress: 0,
        failed: false,
        spoiler: false,
      };
    }),
  );
}

function removeFile(id: string) {
  const foundIndex = attachedImages.value.findIndex((img) => img.id === id);
  if (foundIndex < 0) return;

  const removed = (postForm.value.attachments as PostImageFile[]).splice(
    foundIndex,
    1,
  );
  removed.forEach((rem) => URL.revokeObjectURL(rem.blobUrl));
}

// video handling stuff
const AcceptMimeVideo = [".mp4", ".m4a"];

function updateFilesFromVideo(video: File) {
  const localId = nanoid();

  postForm.value.attachments = {
    source: "local",
    filename: video.name,
    data: video,
    blobUrl: URL.createObjectURL(video),
    index: 0,
    id: localId,
    internalId: localId,
    progress: 0,
    failed: false,
  };
}

function clearAttachments() {
  const video = getPostVideo(postForm.value);
  const images = getPostImages(postForm.value);

  if (video) URL.revokeObjectURL(video.blobUrl);
  else if (images) images.forEach((file) => URL.revokeObjectURL(file.blobUrl));

  richTextEditor.value?.reset();

  postForm.value.attachments = null;
}
</script>

<style scoped lang="postcss">
.post-progress {
  z-index: 3;
  max-width: 400px;
  @apply relative w-full mt-2 bg-neutral-100 rounded-full overflow-hidden
  dark:bg-neutral-500 mx-4;

  &:after {
    content: "";
    width: v-bind(uploadProgressStyle);
    @apply absolute left-0 top-0 h-full bg-nami-nami-blue;
  }
}

.composer-border {
  @apply border-2 !ring-0
  border-transparent
  transition-colors rounded-md;

  &:not([aria-expanded="false"]) {
    @apply focus:border-nami-comi-blue focus-within:border-nami-comi-blue;

    &.locked {
      @apply focus:border-yellow-400 focus-within:border-yellow-400;
    }
  }

  &[aria-expanded="true"] {
    @apply !border-nami-comi-blue;

    &.locked {
      @apply border-yellow-400;
    }
  }
}
</style>
