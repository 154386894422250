import { isPost, Post, Reaction, type ReactionType } from "~/src/api";
import { useSessionStorage } from "@vueuse/core";
import { useBatchFetcher } from "~/composables/async/useBatchFetcher";

const debugStyle =
  "color:white;background-color:rgb(23,152,16);padding:2px 4px;border-radius:4px;";
const debug = (...args: Parameters<typeof console.debug>) =>
  console.debug("%c%s", debugStyle, ...args);

export interface PostMeta {
  id: string;
  userReposted: boolean;
  userReaction: ReactionType | null;
  allReactions: { [key in ReactionType]?: number };
  totalReposts: number | null;
  totalComments: number | null;
  pending?: boolean;
}

const CACHE_DURATION = 1000 * 60 * 5;
const CACHE_KEY = "cache.postmeta";

const postMetaCache = useSessionStorage<{
  [key in string]: PostMeta & { timeout: number };
}>(CACHE_KEY, () => ({}));
const cachePostMeta = (post: PostMeta) => {
  postMetaCache.value[post.id] = {
    ...post,
    timeout: Date.now(),
  };
};
const getCachedPostMeta = (id: string) => {
  const post = postMetaCache.value[id];
  if (!post || Date.now() - post.timeout > CACHE_DURATION) {
    delete postMetaCache.value[id];
    return null;
  }

  return post;
};

const getDefaultMeta = (id: string): PostMeta => ({
  id,
  userReaction: null,
  allReactions: {},
  userReposted: false,
  totalComments: null,
  totalReposts: null,
  pending: true,
});

export const usePostMetaBatch = () => {
  const config = useRuntimeConfig();
  const nuxtApp = useNuxtApp();
  const silence = config.public.environment === "production";
  const authStore = nuxtApp.$auth();

  return useBatchFetcher("post-stats-batcher", async (ids) => {
    // this does not perform refetching of the entire group if a single one is not cached
    // this is due to the lack of batch endpoint from BE
    const cached = ids.map((id) => {
      const cached = getCachedPostMeta(id);
      if (cached?.pending) return null;
      return cached;
    });
    const toBeFetched = ids.filter((_, idx) => !cached[idx]);

    if (toBeFetched.length) {
      if (!silence) debug("Fetching post meta for ids:", ids);
      const res = await Promise.all(
        toBeFetched.map((id) => Post.getPostStatistics(id)),
      );
      await authStore?.waitUntilRefreshIsComplete();

      const [userReposts, userReactions] = authStore?.userEntity
        ? await Promise.all([
            Post.getPosts({
              repostIds: toBeFetched,
              creatorId: authStore.userEntity.id,
              includeReposts: false,
              includeSoftReposts: true,
            }).then((r) =>
              r.data
                .map(
                  (p) =>
                    p.relationships.find(
                      (rel) => rel.type === p.attributes.repostKind,
                    )?.id,
                )
                .filter(onlyTruthys),
            ),
            Reaction.findReactions("post", {
              entityIds: toBeFetched,
              userIds: [authStore.userEntity.id],
              limit: toBeFetched.length,
            }).then((res) =>
              res.data
                .map((reactions) => ({
                  id: reactions.relationships.find(isPost)?.id,
                  type: reactions.attributes.reactionType,
                }))
                .filter(onlyTruthys),
            ),
          ])
        : [[], []];

      res.forEach((meta) => {
        const ret: PostMeta = {
          id: meta.id,
          userReaction:
            userReactions.find((reaction) => reaction.id === meta.id)?.type ??
            null,
          allReactions: meta.attributes.reactions,
          totalComments: meta.attributes.commentCount,
          totalReposts: meta.attributes.extra.repostCount,
          userReposted: userReposts.includes(meta.id),
          // userReposted: false
        };

        cachePostMeta(ret);
      });
    }

    return ids.map((id, idx) => (getCachedPostMeta(id) || cached[idx])!);
  });
};

export const updatePostMeta = (postId: string, changes: Partial<PostMeta>) => {
  const existingMeta = getCachedPostMeta(postId) ?? getDefaultMeta(postId);
  cachePostMeta({
    ...existingMeta,
    ...changes,
  });
};

export const usePostMeta = (postId: string) => {
  const batcher = usePostMetaBatch();

  const fetch = async () =>
    (await batcher.value.getByIds([postId]).catch(() => []))[0];

  return {
    postMeta: computed(
      () => getCachedPostMeta(postId) ?? getDefaultMeta(postId),
    ),
    updatePostMeta: (changes: Partial<PostMeta>) =>
      updatePostMeta(postId, changes),
    fetch,
  };
};
