<script setup lang="ts">
import {
  IconCalendarClock,
  IconDots,
  IconSend,
  IconTrash,
} from "@tabler/icons-vue";
import type { Dayjs } from "dayjs";
import { Post, type PostEntity, type UserEntity } from "~/src/api";
import { POST_INCLUDES, formatBasePost } from "~/src/feed/postUtils";

const { locale } = useNuxtApp().$i18n.global;
const config = useRuntimeConfig();
const dayjs = useDayjs();
const showScheduler = ref(false);
const publishAt = defineModel<string | null>("publishAt", { required: true });
const dayJsModel = ref(dayjs());

const updateDayJsModel = (input: Dayjs, time = false) => {
  let joinedDate;
  const localInput = input.local();

  if (time)
    joinedDate = dayJsModel.value
      .hour(localInput.hour())
      .minute(localInput.minute());
  else
    joinedDate = dayJsModel.value
      .month(localInput.month())
      .date(localInput.date())
      .year(localInput.year());
  dayJsModel.value = joinedDate;
};

const confirm = () => {
  publishAt.value = dayJsModel.value.utc().format();
  showScheduler.value = false;
};

interface Props {
  user?: UserEntity;
  disabled?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "post", v: PostEntity): void;
}>();

const {
  data: pendingPosts,
  pending,
  refresh,
} = useAsyncData(
  "user-pending-posts",
  async () => {
    if (!props.user) return [];

    const res = await Post.getPosts(
      {
        states: ["pending_media_upload", "publish_delayed"],
        order: {
          createdAt: "desc",
        },
        includes: POST_INCLUDES,
        creatorId: props.user.id,
        limit: 100,
      },
      await getTokenOrThrow(),
    );

    return res.data;
  },
  { default: () => [] },
);

const { action: doPublish } = useAction2(async (postId: string) => {
  const foundPost = pendingPosts.value.find((post) => post.id === postId);
  if (!foundPost) return;
  await executeWithNotificationOnError(async () => {
    const post = await Post.publish(
      postId,
      { version: foundPost.attributes.version, publishAt: null },
      await getTokenOrThrow(),
    );
    emit("post", post);
    refresh();
  });
});

const { action: doDelete, pending: deletePending } = useAction2(
  async (postId: string) => {
    const foundPost = pendingPosts.value.find((post) => post.id === postId);
    if (!foundPost) return;

    await executeWithNotificationOnError(async () => {
      await Post.delete(
        postId,
        foundPost.attributes.version,
        await getTokenOrThrow(),
      );
      refresh();
    });
  },
);

const isPendingPost = (postId: string) => {
  const foundPost = pendingPosts.value.find((post) => post.id === postId);
  if (!foundPost) return false;
  return foundPost.attributes.publishAt;
};

const cachePostArr = computed(() =>
  pendingPosts.value.map((post) => formatBasePost(post, config.public.baseUrl)),
);

defineExpose({
  refreshPending: refresh,
});

const tab = ref("schedule");
</script>

<template>
  <NamiButton
    button-type="primary"
    :icon="IconCalendarClock"
    :disabled
    small
    pill
    text
    @buttonClick="() => ((showScheduler = true), (tab = 'schedule'))"
  />
  <NamiModal v-model="showScheduler" large>
    <NamiModalTitle @close="showScheduler = false">
      Schedule Post
    </NamiModalTitle>
    <Tabs
      v-model="tab"
      :tabs="[
        { name: 'Schedule', value: 'schedule' },
        { name: 'Pending Posts', value: 'pending' },
      ]"
      class="!mt-0 !px-0"
    />
    <div class="h-[50vh] overflow-auto space-y-4 flex flex-col !mt-4">
      <template v-if="tab === 'pending'">
        <NamiLoading v-if="pending" />
        <NoResults
          v-else-if="!cachePostArr.length"
          message="No pending posts."
        />
        <template v-else>
          <FeedPostVirtual
            class="border dark:border-neutral-700"
            v-for="post in cachePostArr"
            :feed-item="post"
            embed
            :comments-open="false"
            :is-replying="false"
          >
            <template #options v-if="isPendingPost(post.postId)">
              <ContextMenuList
                defaultPosition="bottom-left"
                close-on-outside-click
                :options="[
                  {
                    name: 'Publish Now',
                    action: () => doPublish(post.postId),
                    icon: IconSend,
                  },
                  {
                    name: 'Delete',
                    action: () => doDelete(post.postId),
                    icon: IconTrash,
                  },
                ]"
              >
                <template v-slot="{ isOpen, open, close }">
                  <NamiButton
                    @buttonClick="() => (isOpen ? close() : open())"
                    button-type="secondary"
                    :icon="IconDots"
                    pill
                    small
                    noWaves
                    :disabled="deletePending"
                    :text="!isOpen"
                  />
                </template>
              </ContextMenuList>
            </template>
          </FeedPostVirtual>
        </template>
      </template>
      <div v-else class="grid grid-cols-2 gap-2">
        <NamiDatePicker
          :locale
          :model-value="dayJsModel"
          @update:model-value="(v) => updateDayJsModel(v)"
          force-position="bottom"
        />
        <NamiTimePicker
          :locale
          :model-value="dayJsModel"
          @update:model-value="(v) => updateDayJsModel(v, true)"
          force-position="bottom"
        />
      </div>
      <div
        v-if="tab === 'schedule'"
        class="flex justify-end !mt-auto space-x-2"
      >
        <NamiButton
          button-type="danger"
          text
          no-waves
          @buttonClick="() => ((showScheduler = false), (publishAt = null))"
        >
          Reset
        </NamiButton>
        <NamiButton
          button-type="primary"
          no-waves
          @buttonClick="confirm"
          :disabled="dayJsModel.valueOf() <= Date.now()"
        >
          Confirm
        </NamiButton>
      </div>
    </div>
  </NamiModal>
</template>
