<template>
  <Card
    v-bind="$attrs"
    :key="feedItem.postId"
    class="sm:tile !p-4 min-w-0 sm:rounded-lg relative text-sm sm:text-base overflow-hidden grid gap-4"
    ref="wrapper"
  >
    <div v-if="false" class="flex space-x-2 items-center py-2">
      <UserAvatar :size="24" />
      <p class="text-neutral-500 flex-grow">{{ feedItem }} likes this</p>
    </div>
    <p
      v-if="feedItem.repostData?.repostedBy?.length && !embed"
      class="-mb-2 text-xs sm:text-sm min-w-0 mr-8 text-neutral-500 truncate"
    >
      <IconRefresh :size="16" class="inline-block flex-shrink-0" />
      Reposted by
      <span
        v-for="(repostPerson, index) in feedItem.repostData.repostedBy"
        class="whitespace-nowrap inline-flex mr-1 items-center min-w-0"
        :class="{
          [`after:content-['and'] after:ml-1`]:
            index === feedItem.repostData.repostedBy.length - 2,
          [`after:content-[',_']`]:
            index < feedItem.repostData.repostedBy.length - 2,
        }"
      >
        <TheNuxtLink
          :to="linkTo(repostPerson.type, repostPerson.id, repostPerson.slug)"
          class="hover:underline"
        >
          {{ repostPerson.displayName }}
        </TheNuxtLink>
      </span>
    </p>
    <div
      class="grid grid-cols-[3rem_1fr] gap-4 items-center"
      v-if="!feedItem.news"
    >
      <TheNuxtLink
        :to="
          feedItem.org
            ? linkTo('org', feedItem.org.slug)
            : linkTo('user', feedItem.author.slug)
        "
      >
        <div class="relative w-12 h-12 flex">
          <FrameImage
            v-if="feedItem.authorFrame && !feedItem.org"
            :frame="feedItem.authorFrame"
            class="absolute inset-0 w-full h-full scale-[1.2]"
          />
          <AsyncImage
            :src="feedItem.org?.avatar ?? feedItem.author.avatar"
            alt="avatar"
            class="w-12 h-12 rounded-md"
            :class="{ '!rounded-full': !feedItem.org }"
          />
        </div>
      </TheNuxtLink>
      <div class="relative mr-6 min-w-0 flex flex-col">
        <TheNuxtLink
          :to="
            feedItem.org
              ? linkTo('org', feedItem.org.slug)
              : linkTo('user', feedItem.author.slug)
          "
        >
          <BadgeUsername
            :badges="feedItem.org ? [] : badges"
            :official="
              feedItem.org ? feedItem.org.official : feedItem.author.official
            "
            :verified="
              feedItem.org ? feedItem.org.verified : feedItem.author.verified
            "
            class="font-medium break-all"
          >
            {{ feedItem.org?.displayName ?? feedItem.author.displayName }}
          </BadgeUsername>
        </TheNuxtLink>
        <div
          class="opacity-70 space-x-1 text-xs sm:text-sm text-neutral-500 flex items-center"
        >
          <TheNuxtLink
            v-if="!feedItem.org"
            :to="linkTo('user', feedItem.author.slug)"
            class="hover:text-neutral-700 dark:hover:text-neutral-300"
          >
            @{{ feedItem.author.slug }}
          </TheNuxtLink>
          <span v-if="!feedItem.org">•</span>
          <TheNuxtLink
            :title="timestampFull"
            :to="`/${route.params.locale}/post/${feedItem.postId}`"
            class="transition hover:text-neutral-700 dark:hover:text-neutral-300"
          >
            {{ timestamp }}
          </TheNuxtLink>
          <span v-if="feedItem.subGated">•</span>
          <BadgeSubscribed
            v-if="feedItem.subGated"
            :unlocked="feedItem.gatedUnlocked"
            :size="18"
            show-text
          />
        </div>
      </div>
      <div
        v-if="!embed || $slots.options"
        class="absolute top-4 z-10 flex items-center right-4"
      >
        <slot name="options">
          <ContextMenuList
            defaultPosition="bottom-left"
            close-on-outside-click
            :options="
              [
                {
                  name: 'Report',
                  action: () => {
                    if (!authStore?.user) appStore?.openLoginRequiredModal();
                    emit('doReport', feedItem.postId);
                  },
                  icon: IconFlag,
                },
                feedItem.author.type === 'user'
                  ? {
                      name: isPosterBlocked ? 'Unblock User' : 'Block User',
                      action: () => {
                        toggleBlockAction.action(feedItem.author.id);
                      },
                      icon: IconForbid,
                    }
                  : null,
                {
                  name: 'Go to post',
                  href: linkTo('post', feedItem.postId),
                  icon: IconArrowRight,
                },
                canDelete &&
                  !hasBeenDeleted && {
                    name: 'Delete',
                    action: doDelete,
                    icon: IconTrash,
                  },
              ].filter(onlyTruthys)
            "
          >
            <template v-slot="{ isOpen, open, close }">
              <NamiButton
                @buttonClick="() => (isOpen ? close() : open())"
                button-type="secondary"
                :icon="IconDots"
                pill
                small
                noWaves
                :text="!isOpen"
              />
            </template> </ContextMenuList
        ></slot>
      </div>
    </div>
    <div v-if="feedItem.subGated">
      <h3 v-if="feedItem.title" class="text-xl font-medium">
        {{ feedItem.title }}
      </h3>
      <p v-if="feedItem.excerpt && !messageStartsWithExcerpt">
        {{ feedItem.excerpt }}...
      </p>
    </div>
    <FeedPostSubBanner
      v-if="feedItem.subGated && feedItem.org && !feedItem.gatedUnlocked"
      :class="{ 'mt-4': feedItem.excerpt || feedItem.title }"
      @login="doLogin()"
      @subscribe="
        navigateTo(linkTo('org', feedItem.org.slug) + '/subscriptions')
      "
      @manage="navigateTo(linkTo('org', feedItem.org.slug) + '/subscriptions')"
      :externalLoading
      :post-id="feedItem.postId"
      :org-id="feedItem.org.id"
      :media="feedItem.postMedia"
    />
    <FeedPostContentBanner
      v-else-if="feedItem.sensitive"
      :embed="feedItem.postEmbed"
      :media="feedItem.postMedia"
      @login="doLogin()"
      @show="doRemoveSpoiler()"
    />
    <div
      v-else-if="feedItem.commentTarget"
      class="break-words mt-4 bg-neutral-200 dark:bg-neutral-800 rounded text-center p-2 h-20 flex items-center justify-center"
      style="word-break: break-word"
    >
      <span>
        This post is a comment portal, the associated entity is
        <TheNuxtLink
          class="text-nami-comi-blue"
          :href="linkTo(feedItem.commentTarget)"
          >{{ linkTo(feedItem.commentTarget) }}</TheNuxtLink
        >
      </span>
    </div>
    <div
      v-else
      class="break-words relative"
      :class="{ 'overflow-hidden rounded-md': feedItem.sensitive }"
      style="word-break: break-word"
    >
      <ShowMore
        :force-open="feedItem.news"
        :max-height="sm ? '4.5rem' : '3.75rem'"
        v-if="postMessage"
      >
        <MarkdownRender :text="postMessage" link-source="s" />
      </ShowMore>
      <FeedEmbedContainer
        class="mt-4"
        v-if="!feedItem.postMedia && feedItem.postEmbed && !feedItem.news"
        :embed="feedItem.postEmbed"
        :post-id="feedItem.postId"
      />
      <FeedImageContainer
        v-if="feedItem.postMedia"
        :class="{ 'mt-4': feedItem.message }"
        :media="feedItem.postMedia"
      />
      <FeedPostVirtual
        v-if="!embed && feedItem.repostData?.targetPost"
        class="mt-4 border border-neutral-300 dark:border-neutral-700"
        :feedItem="feedItem.repostData?.targetPost"
        embed
        :is-replying="false"
        :comments-open="false"
      />
      <div
        v-else-if="!embed && targetPostMissing"
        class="col-start-2 break-words mt-4 bg-neutral-200 dark:bg-neutral-800 rounded text-center p-2"
        style="word-break: break-word"
      >
        The target post was deleted
      </div>
    </div>
    <template v-if="!embed">
      <div
        v-show="
          totalReactions || postMeta?.totalComments || postMeta?.totalReposts
        "
        class="text-xs sm:text-sm flex items-center justify-between space-x-2 -my-2"
      >
        <div
          class="flex space-x-2 items-center cursor-pointer"
          :class="totalReactions && 'h-4'"
          @click="emit('showReactions', feedItem.postId)"
        >
          <ul class="flex space-x-1">
            <li v-for="name in Object.keys(reactionsPreview)">
              <AsyncImage
                :src="getAbsoluteAssetLink(`nami/reactions/${name}.png`)"
                class="w-4 h-4"
              />
            </li>
          </ul>
          <span v-if="totalReactions > 0" class="font-medium">
            {{ totalReactions }}
          </span>
        </div>
        <div class="flex space-x-1 items-center">
          <div
            class="cursor-pointer"
            v-if="postMeta?.totalComments"
            @click="emit('update:commentsOpen', true)"
          >
            {{ translate("feed.count.comments", postMeta.totalComments) }}
          </div>
          <span v-if="postMeta?.totalComments && postMeta?.totalReposts"
            >•</span
          >
          <div
            class="cursor-pointer"
            v-if="postMeta?.totalReposts"
            @click="emit('showReposts', feedItem.postId)"
          >
            {{ translate("feed.count.reposts", postMeta.totalReposts) }}
          </div>
        </div>
      </div>
      <NamiDivider class="!m-0" />
      <div class="grid grid-cols-4 space-x-2 items-center -my-2">
        <FeedPostReactionButton
          small
          :userReaction="postMeta?.userReaction ?? null"
          :disabled="metaLoading"
          @addReaction="(v) => (authStore?.user ? doReaction(v) : doLogin())"
          @removeReaction="doRemoveReaction"
          class="dark:text-neutral-300"
        />
        <NamiButton
          button-type="secondary"
          text
          noWaves
          small
          block
          :icon="IconMessagePlus"
          @click="
            () => {
              if (!commentsOpen) {
                emit('update:commentsOpen', true);
              }
              emit('update:isReplying', !isReplying);
            }
          "
        >
          {{ translate("feed.actions.reply") }}
        </NamiButton>
        <PostRepostButton
          :repost-post="feedItem"
          :update-post-meta="updatePostMeta"
          :disabled="metaLoading"
          :post-meta="postMeta || undefined"
        />
        <ContextMenuList
          defaultPosition="bottom-right"
          :options="[
            {
              name: 'Copy link',
              action: () => emit('copy', feedItem),
              icon: IconCopy,
            },
          ]"
        >
          <template v-slot="{ isOpen, open, close }">
            <NamiButton
              block
              noWaves
              small
              button-type="secondary"
              :text="!isOpen"
              :icon="IconShare"
              @buttonClick="() => (isOpen ? close() : open())"
            >
              {{ translate("feed.actions.share") }}
            </NamiButton>
          </template>
        </ContextMenuList>
      </div>
    </template>
    <NamiDivider class="!m-0" v-if="commentsOpen" />
    <ClientOnly>
      <div v-if="commentsOpen" ref="comments">
        <FeedCommentsSimple
          :feedId="feedItem.postId"
          feedType="post"
          :no-comments="!metaLoading && postMeta?.totalComments === 0"
          :highlightAuthorIds="
            feedItem.org ? [feedItem.org.id] : [feedItem.author.id]
          "
          :highlightAuthorTag="$t('generic.tag.author')"
          @commentPost="updateCommentAmounts(1)"
          @commentDelete="updateCommentAmounts(-1)"
          :disable-replies="
            feedItem.subGated && !!feedItem.org && !feedItem.gatedUnlocked
          "
          :hide-input="
            !isReplying ||
            (feedItem.subGated && !!feedItem.org && !feedItem.gatedUnlocked)
          "
        >
          <template #empty>
            <p class="text-center text-neutral-700 dark:text-neutral-300">
              No comments
            </p>
          </template>
        </FeedCommentsSimple>
      </div>
    </ClientOnly>
    <div
      v-if="isPosterBlocked"
      class="absolute group cursor-not-allowed left-0 top-0 w-full h-full flex justify-center items-center text-white hover:bg-black/75 transition-colors"
    >
      <p class="opacity-0 group-hover:opacity-100 transition-opacity">
        You have blocked this user.
      </p>
    </div>
  </Card>
</template>

<script lang="ts" setup>
import {
  IconArrowRight,
  IconCopy,
  IconDots,
  IconFlag,
  IconForbid,
  IconMessagePlus,
  IconRefresh,
  IconShare,
  IconTrash,
} from "@tabler/icons-vue";

import { Post, Reaction, type ReactionType } from "~/src/api";
import type { CachePost } from "~/src/feed/postUtils";
import { useTimeAgo } from "~/composables/format";
import { bunnycdnMatcher } from "~/composables/useOGData";
import { stripMarkdown } from "~/utils/ui/excerpt";
import {
  type PostMeta,
  updatePostMeta as upm,
} from "~/composables/feed/usePostMeta";
import PostRepostButton from "~/components/post/PostRepostButton.vue";
import { useBlockedUsers } from "~/composables/async/block";

const props = defineProps<{
  feedItem: CachePost;
  embed?: boolean;
  commentsOpen: boolean;
  isReplying: boolean;
  externalLoading?: boolean;
  forceStats?: boolean;
}>();

const emit = defineEmits<{
  (e: "copy", v: CachePost): void;
  (e: "deleted"): void;
  (e: "updateReaction", id: string, v: ReactionType | null): void;
  (e: "showReactions", id: string): void;
  (e: "showReposts", id: string): void;
  (e: "doReport", id: string): void;
  (e: "size", v: number): void;
  (e: "update:commentsOpen", v: boolean): void;
  (e: "update:isReplying", v: boolean): void;
}>();

const dayjs = useDayjs();
const nuxtApp = useNuxtApp();
const route = useRoute();
const locale = nuxtApp.$i18n.global.locale;
const translate = nuxtApp.$i18n.global.t;
const appStore = nuxtApp.$app();
const authStore = nuxtApp.$auth();
const settingsStore = nuxtApp.$settings();
const { sm } = useBreakpoints();
const { blockedUsers, toggleBlockAction } = useBlockedUsers();

const badges = computed(() => {
  let total = 3;
  if (
    props.feedItem.org
      ? props.feedItem.org.official
      : props.feedItem.author.official
  )
    total -= 1;
  if (
    props.feedItem.org
      ? props.feedItem.org.verified
      : props.feedItem.author.verified
  )
    total -= 1;

  return props.feedItem.authorBadges.slice(0, total);
});

const postBatcher = usePostMetaBatch();
const updatePostMeta = (changes: Partial<PostMeta>) => {
  upm(props.feedItem.postId, changes);
  refresh();
};

const metaLoading = computed(
  () =>
    metaPending.value ||
    !postMeta.value ||
    postMeta.value.pending ||
    !!process.server,
);
const {
  data: postMeta,
  refresh,
  pending: metaPending,
} = useAsyncData(`post-meta-${props.feedItem.postId}`, async () => {
  const res = await postBatcher.value.getByIds([props.feedItem.postId]);
  return res[0];
});

const wrapper = ref();
const updateSize = () => {
  if (!wrapper.value) return;
  const height = wrapper.value.$el.getBoundingClientRect().height;
  emit("size", height);
};
const postSizeObserver = ref<ResizeObserver | undefined>();
watch(
  wrapper,
  (v) => {
    if (process.server) return;
    if (!postSizeObserver.value && v?.$el instanceof Element) {
      postSizeObserver.value = new ResizeObserver(updateSize);
      postSizeObserver.value.observe(v.$el);
      updateSize();
    }
  },
  { immediate: true },
);

onMounted(() => {
  authStore?.waitUntilRefreshIsComplete().then(() => refresh());
});

onUnmounted(() => postSizeObserver.value?.disconnect());

const postMessage = computed(() => {
  const message = props.feedItem.message.replace(bunnycdnMatcher, "").trim();
  if (props.feedItem.news) return message.replaceAll("\\n", "<br>");
  return message;
});

const messageStartsWithExcerpt = computed(() => {
  if (!props.feedItem.excerpt) return true;
  return stripMarkdown(postMessage.value).startsWith(props.feedItem.excerpt);
});

const isPosterBlocked = ref(
  blockedUsers.value.some((u) => u.id === props.feedItem.author.id),
);
watch(
  blockedUsers,
  (newBlockedUsers) => {
    isPosterBlocked.value = newBlockedUsers.some(
      (u) => u.id === props.feedItem.author.id,
    );
  },
  { deep: true },
);

const timeAgo = useTimeAgo(locale);

const timestampFull = new Date(props.feedItem.timestamp ?? "");
const timestamp = computed(() => {
  if (timestampFull.valueOf() > Date.now())
    return dayjs(timestampFull).format("LLL");
  return timeAgo.value(timestampFull, "mini-now");
});

const targetPostMissing = (() => {
  if (!props.feedItem.repostData) return false;
  if (props.feedItem.commentTarget) return false;

  return (
    props.feedItem.repostData.originalPostId === props.feedItem.postId &&
    !props.feedItem.repostData.targetPost
  );
})();

const isPerformingAction = ref(false);
const hasBeenDeleted = ref(false);

const reactionsPreview = computed<{ [key in ReactionType]?: number }>(() => {
  return Object.fromEntries(
    Object.entries(postMeta.value?.allReactions ?? {})
      .filter(([, total]) => total > 0)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3),
  );
});

const totalReactions = computed<number>(() => {
  if (Object.values(postMeta.value?.allReactions ?? {}).length === 0) {
    return 0;
  } else {
    return Object.values(postMeta.value?.allReactions ?? {}).reduce(
      (a, b) => a + b,
      0,
    );
  }
});

const canDelete = computed(
  () =>
    // Staff are superusers
    hasAdminAccess(authStore?.user) ||
    // Poster is logged user
    props.feedItem.author.id === authStore?.userEntity?.id,
);

function doRemoveSpoiler() {
  if (!settingsStore?.settings.platform.matureContentEnabled) return;

  props.feedItem.sensitive = false;
  // props.feedItem.postMedia?.images.forEach((img) => (img.spoiler = false));
}

function doLogin() {
  authStore?.login({
    redirectBackTo: route.fullPath,
    locale: reverseLocaleMap(locale.value),
  });
}

// actions

async function doDelete() {
  if (!appStore) {
    return;
  }

  const action = await appStore.prompt(
    `Are you sure you want to delete this post?`,
    {
      icon: "confused",
      forceCloseKey: "cancel",
      buttons: {
        cancel: {
          buttonType: "secondary",
          buttonText: "Cancel",
        },
        confirm: {
          buttonType: "danger",
          buttonText: "Delete",
          icon: IconTrash,
        },
      },
    },
  );

  if (action !== "confirm") {
    return;
  }

  isPerformingAction.value = true;

  const { close } = await appStore.notify({
    preset: "loading.plain",
    detail: "Deleting post...",
  });

  await executeWithNotificationOnError(async () => {
    await withToken(async (token) => {
      const post = await Post.getPost(
        props.feedItem.repostData?.originalPostId ?? props.feedItem.postId,
      );

      return Post.delete(post.id, post.attributes.version, token);
    });

    hasBeenDeleted.value = true;
    emit("deleted");
  }).then(close, close);

  isPerformingAction.value = false;
}

const updateCommentAmounts = (offset = 0) => {
  if (!window) return;

  updatePostMeta({
    totalComments: Math.max((postMeta.value?.totalComments ?? 0) + offset, 0),
  });
};

const updateReactionAmounts = (
  reactionType: ReactionType,
  offsetAmount = 0,
  updateUser = true,
) => {
  if (!window) return;
  const userReaction = postMeta.value?.userReaction!;
  const newData = {
    allReactions: {
      ...postMeta.value?.allReactions,
      [reactionType]: Math.max(
        (postMeta.value?.allReactions![reactionType] ?? 0) + offsetAmount,
        0,
      ),
    },
    userReaction: updateUser
      ? offsetAmount > 0
        ? reactionType
        : null
      : userReaction,
  };

  if (userReaction && offsetAmount > 0) {
    newData.allReactions[userReaction]!--;
  }

  updatePostMeta(newData);
};

async function doReaction(reactionType: ReactionType) {
  const actualId =
    props.feedItem.repostData?.originalPostId ?? props.feedItem.postId;
  emit("updateReaction", actualId, reactionType);
  updateReactionAmounts(reactionType, 1);

  executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) {
      throw new Error("Not logged in.");
    }

    await Reaction.createReaction(
      "post",
      props.feedItem.postId,
      reactionType,
      token,
    );
  }).catch(() => {
    updateReactionAmounts(reactionType, -1);
  });
}

async function doRemoveReaction() {
  const actualId =
    props.feedItem.repostData?.originalPostId ?? props.feedItem.postId;
  emit("updateReaction", actualId, null);
  const userReaction = postMeta.value?.userReaction!;
  updateReactionAmounts(userReaction, -1);

  executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) {
      throw new Error("Not logged in.");
    }

    await Reaction.deleteReaction("post", props.feedItem.postId, token);
  }).catch(() => {
    updateReactionAmounts(userReaction, 1);
  });
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
