<script setup lang="ts">
import { Post, type UserEntity } from "~/src/api";
import FeedPostVirtual from "~/components/feed/post/FeedPostVirtual.vue";
import type { CachePost } from "~/src/feed/postUtils";
import type { PostMeta } from "~/composables/feed/usePostMeta";
import { IconEdit, IconRefresh, IconRefreshOff } from "@tabler/icons-vue";

const props = defineProps<{
  repostPost: CachePost;
  postMeta?: PostMeta;
  updatePostMeta?: (changes: Partial<PostMeta>) => void | Promise<void>;
  disabled?: boolean;
}>();
const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();
const { t: translate } = nuxtApp.$i18n.global;
const { getUserAvatar } = useMediaLink();

const showRepostModal = ref(false);
const showRepostAsModal = ref(false);
const repostAuthor = ref<string | null>(null);

const userEntity = computed<UserEntity | undefined>(
  () => authStore?.userEntity || undefined,
);

const onQuoteRepost = () => {
  appStore?.notify({
    preset: "success.plain",
    detail: "Post has been reposted.",
    timer: 5000,
  });

  showRepostModal.value = false;
};

const { action: doRepost, pending } = useAction2(async () => {
  await executeWithNotificationOnError(async () => {
    assertDefined(authStore);
    assertDefined(authStore.userEntity);

    const token = await getTokenOrThrow();

    showRepostModal.value = false;
    showRepostAsModal.value = false;

    const createdPost = await Post.repost(
      props.repostPost.postId,
      token,
      repostAuthor.value || undefined,
    );
    await Post.publish(
      createdPost.id,
      { version: createdPost.attributes.version },
      token,
    );

    props.repostPost.repostData?.repostedBy.push({
      id: authStore.userEntity.id,
      slug: authStore.userEntity.attributes.username,
      displayName: authStore.userEntity.attributes.displayName ?? "Unknown",
      type: "user",
      avatar: getUserAvatar(authStore.userEntity),
      official: authStore.userEntity.attributes.isOfficial,
      verified: authStore.userEntity.attributes.isVerified,
    });

    if (props.postMeta)
      props.updatePostMeta?.({
        userReposted: true,
        totalReposts: props.postMeta.totalReposts! + 1,
      });

    appStore?.notify({
      preset: "success.plain",
      detail: "Post has been reposted.",
      timer: 5000,
    });
  });
});

const { action: removeRepost, pending: removePending } = useAction2(
  async () => {
    await executeWithNotificationOnError(async () => {
      assertDefined(authStore);
      assertDefined(authStore.userEntity);
      const token = await getTokenOrThrow();

      const repostedPost = await Post.getPosts({
        includeSoftReposts: true,
        includeReposts: false,
        repostIds: [props.repostPost.postId],
        creatorId: authStore.userEntity.id,
      }).then((posts) => posts.data.at(0));

      assertDefined(repostedPost);

      await Post.delete(
        repostedPost.id,
        repostedPost.attributes.version,
        token,
      );
    }).catch(() => {});

    if (props.postMeta)
      props.updatePostMeta?.({
        userReposted: false,
        totalReposts: props.postMeta.totalReposts! - 1,
      });

    appStore?.notify({
      preset: "success.plain",
      detail: "Repost has been removed.",
      timer: 5000,
    });
  },
);

function initiateRepost(immediate?: boolean) {
  if (immediate) {
    doRepost();
    return;
  }
  showRepostModal.value = true;
  repostAuthor.value = null;
}

function initiateRepostAs() {
  showRepostAsModal.value = true;
}

defineExpose({
  doRepost,
  removeRepost,
  initiateRepost,
  initiateRepostAs,
  pending: computed(() => pending.value || removePending.value),
});
</script>

<template>
  <ContextMenuList
    :options="
      [
        {
          name: translate('feed.actions.quotePost'),
          icon: IconEdit,
          action: () => {
            if (!authStore?.user) {
              return appStore?.openLoginRequiredModal();
            }

            initiateRepost();
          },
        },
        postMeta?.userReposted
          ? {
              name: translate('feed.actions.deleteRepost'),
              icon: IconRefreshOff,
              action: () => removeRepost(),
            }
          : {
              name: translate('feed.actions.repost'),
              icon: IconRefresh,
              action: () => {
                if (!authStore?.user) {
                  return appStore?.openLoginRequiredModal();
                }

                initiateRepost(true);
              },
            },
        !postMeta?.userReposted && {
          name: translate('feed.actions.repostAs'),
          icon: IconRefresh,
          action: () => {
            if (!authStore?.user) {
              return appStore?.openLoginRequiredModal();
            }

            initiateRepostAs();
          },
        },
      ].filter(onlyTruthys)
    "
  >
    <template v-slot="{ isOpen, open, close }">
      <NamiButton
        :buttonType="postMeta?.userReposted ? 'primary' : 'secondary'"
        noWaves
        block
        :text="!isOpen"
        small
        :disabled="disabled || pending || removePending"
        :icon="IconRefresh"
        @click="() => (isOpen ? close() : open())"
      >
        {{ translate("feed.actions.repost") }}
      </NamiButton>
    </template>
  </ContextMenuList>
  <NamiModal v-model="showRepostModal" large inherit>
    <PostAddEditForm
      v-if="repostPost?.postId"
      :quote-post-id="repostPost!.postId"
      sync
      :user="userEntity"
      class="!rounded-lg lg:min-w-[500px] 2xl:min-w-[700px]"
      show-close
      @close="showRepostModal = false"
      @post="onQuoteRepost"
    />
  </NamiModal>
  <NamiModal v-model="showRepostAsModal" large>
    <NamiModalTitle @close="showRepostAsModal = false">Repost</NamiModalTitle>
    <div class="space-y-4">
      <div class="text-lg font-medium">Reposting as:</div>
      <PostAuthorSelect v-model="repostAuthor" :user="userEntity" />
      <FeedPostVirtual
        :feed-item="repostPost"
        :comments-open="false"
        :is-replying="false"
        embed
      />
    </div>
    <div class="flex justify-end space-x-4 pt-4">
      <NamiButton
        buttonType="secondary"
        @buttonClick="showRepostAsModal = false"
      >
        Close
      </NamiButton>
      <NamiButton buttonType="primary" @buttonClick="doRepost">
        Post
      </NamiButton>
    </div>
  </NamiModal>
</template>
