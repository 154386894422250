import { Gating, Post, Video } from "~/src/api";
import type { PostForm } from "~/types/forms/post";
import {
  determinePostKind,
  getPostForm,
  getPostImages,
  getPostVideo,
} from "~/src/forms/post/common";
import { getExcerpt } from "~/utils/ui/excerpt";

export async function createPost(
  form: PostForm,
  getToken = async (): Promise<string | null | undefined> => undefined,
  onProgress = (percent: number) => {},
): Promise<PostForm> {
  const token = (await getToken()) || undefined;
  if (!token) throw new Error("Not logged in");

  const message = form.content.trim().replace(/(\n(?=\n))+/, "\n") || undefined;
  const postKind = determinePostKind(form);
  const postVideo = getPostVideo(form);
  const postImages = getPostImages(form);

  const draftResponse = await Post.create(
    {
      kind: postKind,
      content: message,
      title: form.title.trim() || undefined,
      contentExcerpt:
        form.contentExcerpt.trim() ||
        getExcerpt(message ?? "").trim() ||
        undefined,
      organizationId: form.organizationId || undefined,
      repost: form.repostId || undefined,
      sensitive: form.sensitive || undefined,
      gating: form.minAmount > 0 ? "default_locked" : undefined,
      embed: postVideo
        ? {
            kind: "video",
            url: "VIDEO_UNSET",
          }
        : undefined,
    },
    token,
  );

  let condition;
  if (form.minAmount > 0) {
    condition = await Gating.createPostCondition(
      {
        postId: draftResponse.id,
        strategy: "subscribed_tier_amount",
        parameters: {
          amount: form.minAmount,
          comparison: "gte",
        },
      },
      token,
    );
  }

  if (postVideo) {
    const createdVideo = await Video.create(draftResponse.id, token);

    const newPost = await Post.attachVideo(
      draftResponse.id,
      {
        version: draftResponse.attributes.version,
        content: draftResponse.attributes.content || form.content || undefined,
        gating: draftResponse.attributes.gating,
        title: draftResponse.attributes.title,
        contentExcerpt: draftResponse.attributes.contentExcerpt,
      },
      token,
    );

    await new Promise((resolve, reject) => {
      Video.upload(
        {
          AuthorizationSignature: createdVideo.attributes.signature,
          AuthorizationExpire:
            createdVideo.attributes.authorizationExpiration.toString(),
          VideoId: createdVideo.attributes.videoId,
          LibraryId: createdVideo.attributes.libraryId,
        },
        postVideo.data,
        {
          onSuccess() {
            resolve(null);
          },
          onError(error) {
            reject(error);
          },
          onProgress(bytesUploaded, bytesTotal) {
            onProgress(bytesUploaded / bytesTotal);
          },
        },
      );
    });

    return getPostForm(newPost, condition, form.publishAt);
  }

  if (postImages) {
    const total = postImages.length;
    const progresses = Object.fromEntries(
      postImages.map((img) => [img.internalId, img.progress]),
    );

    let final = draftResponse;
    for (const file of postImages) {
      final = await Post.uploadMedia(
        final.id,
        final.attributes.version,
        file.data,
        token,
        file.spoiler,
        (e) => {
          progresses[file.internalId] = e.progress ?? 0;
          const currProgress = Object.values(progresses).reduce(
            (a, b) => a + b,
            0,
          );
          onProgress(currProgress / total);
        },
      );
    }

    return getPostForm(final, condition, form.publishAt);
  }

  return getPostForm(draftResponse, condition, form.publishAt);
}
